* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
}

.container {
    position: relative;
    min-height: 100vh;
}

a, button, label {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.container--adminLogin {
    background: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin__login {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateY(-30px);
}

.btn--adminLogin {
    width: 300px;
    height: 45px;
    border-radius: 45px;
}

.admin__login .input {
    width: 300px;
    height: 50px;
    margin-bottom: 15px;
}

.admin__login .input::placeholder {
    color: rgba(255, 255, 255, .5);
}

.admin__login__logo {
    width: 400px;
    height: auto;
}

.admin__login__header {
    margin: 30px auto 60px;
    color: #fff;
    text-align: center;
}

.panelMenu {
    width: 20%;
    background: transparent;
    height: calc(100vh - 100px);
    overflow-y: auto;
    position: fixed;
    bottom: 0;
    left: 0;
    border-right: 2px solid var(--secondary);
    z-index: 2;
}

.panelMenu__list {
    list-style-type: none;
    padding-top: 20px;
}

.panelMenu__list__item {
    border-bottom: 1px solid var(--secondary);
}

.panelMenu__list__item__link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 70px;
    color: #D4D4D4;
    background: transparent;
    padding: 10px 40px;
    font-size: 18px;
    cursor: pointer;
    position: relative;
    transition: .2s all;
}

.panelMenu__list__item__link:not(.panelMenu__list__item__link--selected):hover {
    opacity: .75;
}

.panelMenu__list__item__link .panelMenu__list__item__icon {
    filter: brightness(0) invert(1);
}

.panelMenu__list__item__icon {
    width: 20px;
    height: auto;
    margin-right: 20px;
}

.admin {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.panelMenu__submenu {
    background: transparent;
    padding-left: 20%;
}

.panelMenu__submenu__link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    color: #fff;
    font-size: 18px;
    padding: 35px 0 35px 5%;
    transition: .2s all;
}

.panelMenu__submenu__link:hover {
    background: var(--secondary);
    color: #fff;
}

.panelMenu__list__item__link--selected {
    background: var(--secondary);
    color: #fff;
}

.panelMenu__list__item__link--selected .panelMenu__list__item__icon {
    filter: brightness(0) invert(1);
}

.adminTop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background: var(--primary);
    width: 100%;
    height: 100px;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: visible;
    border-bottom: 1px solid var(--secondary);
}

.adminTop__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}

.adminTop__logoWrapper {
    display: block;
    width: 270px;
    height: auto;
    margin-top: 10px;
}

.adminTop__header {
    font-size: 17px;
}

.adminTop__header__key {
    color: #D4D4D4;
    font-weight: 400;
}

.adminTop__header__value {
    font-weight: 700;
    padding-left: 10px;
}

.adminTop__right .profileMenu::before {
    right: 10%;
}

.adminTop__right .siteHeader__player__btn {
    margin-left: 30px;
}

.profileMenu {
    position: absolute;
    bottom: -10px;
    right: 2px;
    transform: translate(0, 100%);
    background: #fff;
    width: 200px;
    padding: 15px 0;
}

.profileMenu::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 17%;
    transform: translateY(-100%);
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #fff;
    background: transparent;
    z-index: 3;
}

.profileMenu--admin {
    bottom: -35px;
}

.profileMenu--club::before {
    border-bottom-color: var(--secondary);
}

.profileMenu--club {
    background: var(--secondary);
}

.profileMenu__list {
    list-style-type: none;
}

.profileMenu__list__link {
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: transparent;
    width: 100%;
    font-size: 14px;
    transition: .2s all;
}

.profileMenu--club .profileMenu__list__link {
    color: #fff;
}

.profileMenu--club .profileMenu__list__link:hover {
    opacity: .5;
}

.profileMenu__list__img {
    width: 25px;
    height: auto;
    min-height: 100%;
    margin-right: 20px;
}

.siteHeader__player {
    background: #fff;
    border-radius: 8px;
    padding: 15px;
    height: 70px;
    margin-left: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.siteHeader__player--club {
    background: #3D3D3D;
}

.siteHeader__player__btn {
    background: transparent;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px;
    position: relative;
}

.siteHeader__player__btn__img {
    width: 100%;
}

.img--envelope {
    margin-bottom: -12px;
}

.siteHeader__player--club .img--envelope {
    margin-bottom: 0;
}

.siteHeader__player__btn--profile {
    width: 55px;
    height: 55px;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #707070;
}

.siteHeader__player__btn--profile__img {
    min-width: 100%;
    min-height: 100%;
}

.scroll::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
.scroll::-webkit-scrollbar-button {
    width: 5px;
    height: 5px;
}
.scroll::-webkit-scrollbar-thumb {
    background: var(--secondary);
    border: none;
    border-radius: 31px;
}
.scroll::-webkit-scrollbar-track {
    background: var(--primary);
    border: none;
    border-radius: 5px;
}
.scroll::-webkit-scrollbar-corner {
    background: transparent;
}

.panelMenu__submenu__link--special {
    color: #fff;
    width: 100%;
    height: 100%;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.admin__main {
    position: relative;
    padding: 50px 30px 30px 50px;
    width: 80%;
    margin: 100px 0 0 20%;
}

.admin__main__header {
    position: relative;
    color: var(--secondary);
    text-transform: uppercase;
}

.container--addProduct .input {
    width: 100%;
    height: 50px;
    display: block;
    margin-top: 5px;
}

.container--addProduct .admin__label,
.container--addProduct label:not(.admin__editorWrapper label) {
    color: #fff;
    margin: 30px 15vw 30px 0;
    font-size: 13px;
    display: block;
}

.addProduct__addonsSection__condition {
    line-height: 2em;
}

.addProduct__addonsSection__condition label {
    color: #000 !important;
    margin: 0 10px !important;
}

.rdw-link-modal, .rdw-link-modal * {
    color: #000 !important;
}

.rdw-link-modal label {
    margin: 0 !important;
}

.addProduct__addonsSection__condition label * {
    color: #000 !important;
}

.singleOption .admin__label {
    position: relative;
    margin-right: 0;
}

.container--addProduct .flex {
    justify-content: flex-start;
}

.rdw-editor-wrapper {
    margin-top: 10px;
    width: 100%;
}

.rdw-editor-wrapper,
.rdw-editor-wrapper * {
    color: #000;
}

.editor {
    background: #fff;
    margin-top: 0;
    color: #000;
    padding: 0 15px;
    min-height: 200px;
}

.btn--admin {
    width: 100%;
    font-size: 21px;
    font-weight: 700;
}

.btn--choose {
    border-radius: 4px;
    width: 200px;
    margin: 15px 25px 0 0;
    height: 40px;
    background: transparent;
    border: 2px solid var(--secondary) !important;
}

.btn--choose:hover {
    opacity: .8;
    background: transparent;
}

.btn--choose--selected {
    background: var(--secondary) !important;
}

.btn--choose--selected:hover {
    opacity: 1;
}

.btn--addOption {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    font-size: 36px;
    font-weight: 700;
    margin-left: 30px;
    flex-basis: 20%;
    max-width: 45px;
}

.optionsHeader {
    margin: 40px 0 10px;
    padding-top: 40px;
    border-top: 2px dashed var(--secondary);
}

.singleOption {
    margin-top: -25px;
}

.singleOption > label {
    width: 100%;
}

.singleOption .input {
    width: 100%;
}

.singleOption__top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.singleOption__top .btn--addOption {
    width: 35px !important;
    height: 35px !important;
    min-width: 35px !important;
    max-height: 35px !important;
    background: #d83b27;
}

.singleOption__number {
    margin-right: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--secondary);
    color: #fff;
    border-radius: 50%;
    width: 35px;
    height: 35px;
}

.singleOption {
    margin-bottom: 10px;
    padding-top: 40px;
    border-bottom: 1px dashed var(--secondary);
}

.admin__label__imgUpload__updateImg,
.dzu-previewContainer img {
    width: 200px;
    height: auto;
}

.dzu-inputLabel,
.dzu-dropzone > label {
    margin-top: 5px;
    color: transparent !important;
}

.dzu-dropzone > label > input {
    color: #fff;
}

.singleOption > label {
    margin-right: 3vw;
    position: relative;
}

.admin__label__imgUpload__trashBtn {
    position: absolute;
    top: 10px;
    right: -20px;
    width: 40px;
    height: 40px;
    color: #fff;
    font-size: 36px;
    font-weight: 500;
    border-radius: 50%;
    background: var(--secondary);
    z-index: 8;
}

.dzu-previewStatusContainer {
    display: none;
}

.modalMenu,
.colorModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .6);
    transition: .4s all;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.modal__inner,
.colorModal__inner {
    width: 450px;
    height: 500px;
    max-width: 90vw;
    max-height: 100vh;
    background: var(--primary);
    border-radius: 15px;
    border: 1px solid #313131;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.colorModal__text {
    color: #fff;
    text-align: center;
    margin: 20px auto;
}

.colorModal__inner label {
    margin: 0;
}

.btn--openColorModal {
    height: 40px;
    width: 150px;
    margin-top: 10px;
    border-radius: 3px;
}

.colorModal__header {
    color: var(--secondary);
    margin-bottom: 25px;
}

.btn--changeColor {
    width: 200px;
    height: 40px;
    margin-top: 20px;
}

.colorModal__close {
    position: absolute;
    top: 0;
    right: 15px;
    background: transparent;
    color: #fff;
    font-size: 36px;
    font-weight: 500;
}

.showColor {
    width: 40px;
    height: 40px;
    display: block;
    margin-left: 20px;
    border-radius: 5px;
    margin-top: 10px;
    border: 2px solid var(--secondary);
}

.deleteOptionBtn {
    background: var(--secondary);
    margin-left: 50px;
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-top: 15px;
    transition: .2s all;
}

.deleteOptionBtn:hover {
    background: var(--hover);
}

.deleteOptionBtn .btn__img {
    width: 40%;
}

.dzu-inputLabel {
    margin-right: 0 !important;
}

.dzu-input {
    margin-left: -185px;
}

.container--addProduct .admin__flex {
    margin-right: 0;
}

.admin__status {
    position: absolute;
    top: 50px;
    left: 400px;
}

.admin__status--sendMail {
    left: 75%;
}

.admin__status--right {
    left: unset;
    right: 10%;
}

.admin__status--stock {
    left: 500px;
}

.admin__status__inner {
    width: auto;
    padding: 5px 15px;
    font-size: 15px;
    color: #fff;
    text-transform: uppercase;
    border: none;
    border-radius: 5px;
}

.admin__status--success {
    background: green;
}

.admin__status--error {
    background: #d83b27;
}

div[data-testid=three-dots-loading] {
    justify-content: center;
    align-items: center;
}

.admin__main__notification__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
    padding: 30px 20px;
    border-bottom: 1px solid var(--secondary);
}

.admin__notification__item__col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.admin__main__notification__item__col > * {
    font-size: 15px;
    text-align: center;
}

.admin__main__notification__item__key {
    color: #fff;
    text-decoration: underline;
    font-weight: 400;
}

.admin__main__notification__item__value {
    color: #fff;
    margin-top: 15px;
}

.admin__main__notification__item__value--small {
    font-size: 13px;
}

.admin__main__notification__item .col-1 {
    flex-basis: 15%;
    width: 20%;
}

.admin__main__notification__item .col-2 {
    flex-basis: 25%;
}

.admin__main__notification__item .col-3 {
    flex-basis: 35%;
}

.admin__main__notification__item .col-4 {
    flex-basis: 30%;
}

.admin__main__notification__item__imgWrapper {
    height: 100px;
    width: auto;
}

.admin__main__notification__item__imgWrapper .btn__img {
    height: 100%;
    width: auto;
}

.admin__main__notification__item__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin__main__notification__item__btn {
    margin: 20px 10px 0;
    width: 20px;
    opacity: .7;
    transition: .2s all;
}

.admin__main__notification__item__btn--block {
    background: transparent;
}

.admin__main__notification__item__btn:hover {
    opacity: 1;
}

.admin__main__notification__item--addon .admin__main__notification__item__col {
    flex-basis: 30%;
}

.admin__label__oldImagePreviewWrapper,
.imagePreview {
    max-width: 250px;
    margin-top: 20px;
}

.img {
    width: 100%;
}

.addProduct__addonsSection {
    margin: 40px 0 10px;
    padding: 30px 0 10px;
    border-top: 2px dashed #A8905A;
}

.addProduct__addonsSection__header {
    color: #fff;
}

.addProduct__addonsSection__btn {
    width: 30px;
    height: 30px;
    border: 1px solid var(--secondary);
    background: transparent;
    position: relative;
    margin-right: 15px;
}

.addProduct__addonsSection__btn--selected::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 60%;
    background: var(--secondary);
}

.addProduct__addonsSection__label {
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin: 20px 40px 20px 0 !important;
}

.addProduct__addonsSection__label span {
    font-size: 16px;
}

.btn--admin--type {
    margin-right: 15vw;
    width: calc(100% - 15vw);
}

.uploadGalleryWrapper {
    margin-top: 30px;
}

.rug .rug-handle:before {
    border: 2px dashed #fff !important;
}

.rug-handle-info span,
.rug-handle-drop-text {
    color: #fff !important;
}

.rug-handle svg {
    filter: brightness(0) invert(1);
}

.editor__mainImageWrapper {
    width: 100%;
    max-width: 600px;
    position: relative;
}

.editor__mainImage__placeholder {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    border: 2px dashed #d8d8d8;
    z-index: 44;
}

.deleteOldImg {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 24px;
    padding: 0;
    border: 1px solid #313131;
    outline: none;
    z-index: 50;
    cursor: pointer;
    transition: .2s all;
}

.deleteOldImg:hover {
    background: #313131;
    color: #fff;
}

.editor__video {
    display: block;
    height: 100%;
    width: auto;
    max-width: 100%;
}

.editor__files__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
}

.oldImgWrapper {
    position: relative;
    height: 300px;
    border: 2px dashed #fff;
    z-index: 8;
    transition: .2s all;
}

.editor__videoWrapper__placeholderContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.editor__videoWrapper__icon {
    width: 80px;
    height: auto;
    filter: brightness(0) invert(1);
    opacity: .8;
}

.editor__videoWrapper__placeholderContent__text {
    color: #fff;
    margin-bottom: 20px;
    text-align: center;
}

.opacity-0 {
    opacity: 0;
}

.z-index-0 {
    /*z-index: 0;*/
}

.z-index-1000 {
    z-index: 99;
}

.addProduct__addonsSection__main__item {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.addProduct__addonsSection__addCondition {
    font-size: 12px;
    color: #fff;
    background: transparent;
    text-decoration: underline;
    white-space: nowrap;
}

.addProduct__addonsSection__condition {
    color: #fff;
    font-size: 13px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    flex-wrap: wrap;
}

.addProduct__addonsSection__condition select {
    /*margin: 0 10px;*/
    max-width: 100%;
}

.space {
    padding-right: 10px;
}

.addProduct__addonsSection__condition select option {
    max-width: 100%;
}

.addProduct__addonsSection__condition label {
    margin: 0;
}

.addProduct__addonsSection__header--addons {
    margin-bottom: 20px;
}

.input--textarea {
    resize: none;
    height: 100px !important;
}

.addAddon--imageHeader {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: -15px;
}

.editorWrapper__header {
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    margin-top: 40px;
}

.btn--marginTop {
    margin-top: 30px;
}

.adminBlog__images > div {
    width: 45%;
}

.admin__label__imgUpload {
    display: block;
    width: 100%;
    height: 300px;
    border: 2px dashed #fff;
    position: relative;
}

.admin__label--imageForLink input {
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    z-index: 9999;
}

.modal__inner {
    background: var(--primary);
    width: 700px;
    height: 400px;
    border: 1px solid #fff;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal__close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    background: transparent;
    color: #fff;
    border: none;
    font-size: 36px;
}

.modal__header {
    color: #fff;
}

.modal__link {
    color: #fff;
    font-size: 15px;
    margin: 30px auto;
    text-align: center;
}

.modal__btn {
    background: var(--secondary);
    width: 150px;
    height: 40px;
    border-radius: 5px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    transition: .2s all;
}

.modal__btn:hover {
    background: var(--hover);
}

.adminBlog__images > div > .admin__label {
    margin-bottom: 0;
}

.admin__label__imgUpload__trashBtn--newsletter,
.adminBlog__images .admin__label__imgUpload__trashBtn {
    width: 35px;
    height: 35px;
    top: -10px;
    background: var(--secondary);
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin__label__imgUpload__trashBtn--newsletter .img,
.adminBlog__images .admin__label__imgUpload__trashBtn .img {
    width: 45%;
}

.adminBlog__images .dzu-previewContainer img {
    max-width: 100%;
    max-height: 300px;
    height: 100%;
    width: auto;
}

.newsletterList__item:first-of-type {
    margin-top: 30px;
}

.newsletterList__item {
    color: #fff;
    margin: 10px 0;
    padding: 10px 0;
    border-bottom: 1px dashed var(--secondary);
    font-size: 21px;
}

.addProduct__addonsSection__labelForInput {
    width: 140px;
    margin-right: 10px !important;
    color: #fff;
    font-style: italic;
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start;
    align-items: center;
}

.addProduct__addonsSection__input::-webkit-outer-spin-button,
.addProduct__addonsSection__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.addProduct__addonsSection__input {
    -moz-appearance: textfield;
    border: 1px solid var(--secondary);
    border-radius: 5px;
    padding: 5px;
    width: 40px;
    height: 40px;
    background: transparent;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 700;
    margin-left: 10px;
    text-align: center;
}

.admin__main__notification__item__value > span {
    display: block;
    text-align: center;
}

.admin__order__left {
    width: 30%;
    padding-right: 30px;
    border-right: 2px dashed var(--secondary);
}

.orderStatus {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 40px 0;
    background: transparent;
}

.orderStatus__number {
    background: var(--secondary);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #313131;
    opacity: .5;
    transition: .2s all;
    font-size: 21px;
    font-weight: 700;
    position: relative;
}

.orderStatus__number--newStatus ~ .orderStatus__text,
.orderStatus__number--newStatus {
    text-decoration: underline;
}

.orderStatus__number--currentStatus {
    opacity: 1;
}

.orderStatus__number--currentStatus::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 125%;
    height: 125%;
    background: transparent;
    border: 1px solid var(--secondary);
    border-radius: 50%;
}

.orderStatus__text {
    width: calc(100% - 60px);
    text-transform: uppercase;
    font-size: 19px;
    color: var(--secondary);
    margin-left: 25px;
    text-align: left;
}

.btn--orderStatus {
    font-size: 15px;
    height: 45px;
    border-radius: 45px;
}

.info--orderStatus {
    text-align: center;
    display: block;
    width: 100%;
    color: #fff;
}

.admin__order__right {
    width: 60%;
    padding-left: 40px;
}

.admin__order__right__header {
    text-transform: uppercase;
    color: var(--secondary);
    font-size: 21px;
    margin-bottom: 10px;
}

.admin__order__right__text > span {
    display: block;
    color: #fff;
}

.admin__order__right__text > span > span {
    font-style: italic;
    padding-right: 10px;
}

.admin__main__order {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 50px;
}

.admin__order__right > .flex {
    align-items: flex-start;
}

.admin__order__cart {
    width: 100%;
    margin-top: 60px;
}

.admin__order__cart__header {
    background: var(--secondary);
}

.admin__order__cart__header,
.admin__order__cart__item {
    color: #fff;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    height: auto;
    min-height: 50px;
}

.admin__order__cart > div > span {
    flex-basis: 24%;
    text-align: center;
}

.admin__order__cart__item {
    padding: 10px 0;
    border-bottom: 1px dashed var(--secondary);
}

.admin__order__cart__item__btn {
    background: transparent;
    width: 25px;
}

.admin__order__cart__item__details {
    width: 100%;
    margin: 25px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.admin__order__cart__item__details__addon {
    color: #fff;
    padding-left: 5%;
    font-size: 14px;
    font-style: italic;
}

.admin__order__cart__item__details__addons,
.admin__order__cart__item__details__buttons {
    width: 48%;
}

.admin__order__cart__item__details__btn {
    background: var(--secondary);
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    width: 100%;
    height: 40px;
    border-radius: 40px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s all;
}

.admin__order__cart__item__details__btn:hover {
    background: var(--hover);
}

.o-5 {
    opacity: .3;
    pointer-events: none;
}

.admin__order__cart__item__btn .btn__img {
    opacity: .7;
    transition: .2s all;
}

.admin__order__cart__item__btn .btn__img:hover {
    opacity: 1;
}

.admin__form {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.admin__form__section {
    width: 47%;
}

.admin__form__field {
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.admin__form__field > span {
    color: #fff;
    font-size: 15px;
    display: block;
    margin-bottom: 10px;
}

.admin__form__field__value {
    padding: 10px 15px;
    border: 1px solid var(--secondary);
    border-radius: 5px;
    color: #fff;
}

.admin__form__field__imageWrapper {
    width: 300px;
    height: auto;
}

.admin__form {
    border-top: 2px dashed var(--secondary);
    margin-top: 30px;
}

.admin__form__section > .admin__order__right__header {
    margin-top: 30px;
    padding: 10px 0;
}

.admin__form__section:first-of-type {
    padding-right: 5%;
    border-right: 1px dashed var(--secondary);
}

.admin__form__field__openInNewTabBtn {
    color: #fff;
    padding-left: 20px;
    font-size: 13px;
    font-style: italic;
    text-decoration: underline;
}

.admin__main__subheader {
    color: #fff;
}

.admin__main__subheader:first-of-type {
    margin: 20px 0 8px;
}

.admin__main__subheader > span {
    font-weight: 400;
    font-style: italic;
    padding-right: 8px;
}

.admin__start__section {
    border: 1px solid var(--secondary);
    border-radius: 15px;
    padding: 20px;
    width: 46%;
}

.admin__start__section:first-child {
    margin-right: 4%;
}

.admin__start__section__header {
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.admin__start {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 40px;
}

.admin__start__stats {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px 0 10px;
    width: 30%;
}

.admin__start__stats > span {
    color: #fff;
}

.admin__start__stats__value {
    font-size: 32px;
    font-weight: 700;
}

.admin__start__stats__key {
    font-size: 12px;
    text-align: center;
}

.admin__start__statsWrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
}

.quickImg {
    width: 25px;
}

.admin__start__section--100 {
    width: 96%;
    margin-top: 40px;
}

.admin__start__section--100 .admin__start__section__header {
    margin-bottom: 10px;
}

.contactWithSupportText {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    font-style: italic;
    margin: 10px 0 20px;
}

.admin__contact__textarea {
    border: 1px solid var(--secondary);
    padding: 15px;
    color: #fff;
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
    background: transparent;
    resize: none;
    outline: none;
}

.btn--admin--contact {
    height: 45px;
    border-radius: 45px;
    font-size: 17px;
}

.info--adminContact {
    color: #fff;
    text-align: center;
    font-size: 17px;
    margin: 50px 0;
}

.admin__label__color,
.admin__label__imgUpload {
    margin-top: 5px;
}

.admin__label__color {
    position: relative;
    z-index: 5;
}

.admin__label__imgUpload {
    position: relative;
    width: 300px;
}

.d-none--margin {
    padding-bottom: 20px;
    visibility: hidden;
    height: 0;
}

.d-none--margin * {
    display: none !important;
}

/*.admin__label__imgUpload .dzu-dropzone::after {*/
/*    content: "";*/
/*    background-image: url("../img/image-gallery.svg");*/
/*    background-repeat: no-repeat;*/
/*    background-size: contain;*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    transform: translate(-50%, -50%);*/
/*    width: 80px;*/
/*    height: 90px;*/
/*    font-size: 12px;*/
/*    filter: brightness(0) invert(1);*/
/*    pointer-events: all;*/
/*    z-index: 1;*/
/*}*/

.singleOption .dzu-input {
    opacity: 0;
}

.singleOption .dzu-dropzone {
    position: relative;
    z-index: 6;
    background: transparent;
}

.singleOption .dzu-dropzone,
.singleOption .dzu-input,
.singleOption .dzu-inputLabel {
    height: 100%;
    width: 100%;
    margin: 0;
    cursor: pointer;
}

.singleOption .admin__label__oldImagePreviewWrapper,
.singleOption .imagePreview {
    max-width: 100%;
    margin-top: 0;
}

.singleOption .admin__label__imgUpload__trashBtn {
    top: -20px;
}

.admin__label__imgUpload__placeholder {
    color: #fff;
    font-size: 13px;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: center;
    z-index: 5;
}

.admin__label__imgUpload__placeholder .img {
    width: 50px;
    height: auto;
    margin-bottom: 15px;
    filter: brightness(0) invert(1);
}

.adminBlog__imagpanelMenu__list__item__linkes .admin__label__imgUpload {
    width: 100%;
    cursor: pointer;
}

.adminBlog__images .editor__videoWrapper__placeholderContent__text {
    font-size: 15px;
}

a.admin__start__stats {
    opacity: .8;
    transition: .2s all;
}

a.admin__start__stats:hover {
    opacity: 1;
}

.adminBlog__images > div {
    width: calc(50% - 25px);
    max-width: 600px;
}

.adminBlog__images > div:first-of-type {
    margin-right: 40px;
}

.modalMenu .modal__inner {
    height: 300px;
}

.modal__inner .panelMenu__submenu__item {
    width: 100%;
    text-align: center;
}

.modal__inner .panelMenu__submenu__link:hover,
.modal__inner .panelMenu__submenu__item:hover {
    background: transparent;
}

.modal__inner .panelMenu__submenu__link {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: underline;
}

.modalMenu .modal__header {
    margin-bottom: 10px;
}

.addProduct__addonsSection__label span {
    flex-basis: 80%;
}

.admin__form__field__imageWrapper {
    max-width: 100%;
}

.addProduct__addonsSection__addonOptions {
    margin-bottom: 15px;
}

.addProduct__addonsSection__addonOptions i {
    font-weight: 700;
}

.addProduct__addonsSection__addonOptions i,
.addProduct__addonsSection__addonOptions span {
    color: #fff;
    font-size: 13px;
}

.addProduct__addonsSection__addonOptions span {
    padding-left: 6px;
}

.admin__order__sendInfo {
    margin-top: 40px;
}

.admin__order__sendInfo > div {
    margin-bottom: 25px;
}

.admin__order__sendInfo__header {
    margin-bottom: 15px;
    color: var(--secondary);
    font-size: 17px;
    text-transform: uppercase;
}

.admin__order__sendInfo .input--textarea {
    width: 100%;
    max-width: 400px;
    height: 150px;
    margin-bottom: 20px;
}

.btn--admin--send {
    height: 40px;
    border-radius: 40px;
    max-width: 350px;
    font-size: 15px;
}

.btn--danger {
    height: 40px;
    border-radius: 40px;
    width: 300px;
    background: #d83b27;
}

.btn--danger:hover {
    background: #d83b27;
    opacity: .8;
}

.info--infoForClient {
    text-align: left;
    margin: 0 0 20px;
}

.input--deliveryNumber {
    margin-bottom: 20px;
    width: 300px;
    height: 50px;
    border-radius: 5px;
}

.input--priority {
    max-width: 200px;
}

.editor img {
    display: block;
    max-width: 100% !important;
}

#root > div > div > main > div > div:nth-child(2) > label > span > div.dzu-dropzone > label {
    opacity: 0;
}

.admin__main__backBtn {
    font-size: 15px;
    font-weight: 400;
    position: absolute;
    top: 0;
    right: 0;
    text-transform: none;
}

.admin__main__backBtn .img {
    width: 14px;
    margin-right: 8px;
    transition: .2s all;
}

.admin__main__backBtn:hover .img {
    transform: translateX(-5px);
}

.btn--downloadZip {
    padding: 15px 10px;
    height: 45px;
    border-radius: 5px;
    margin: 20px 0;
    text-transform: none;
}

.admin__main__notification__item__value--addonOptions > span {
    display: block;
}

.admin__value--small {
    font-size: 13px !important;
    font-style: italic;
    font-weight: 400;
}

.admin__addonStock__input {
    padding: 10px;
    width: 100px;
    height: 30px;
    background: transparent;
    border: 1px solid var(--secondary);
    border-radius: 3px;
    margin: 10px auto;
    display: block;
}

.admin__addonStock__input::-webkit-outer-spin-button,
.admin__addonStock__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.admin__addonStock__input[type=number] {
    -moz-appearance: textfield;
}

.btn--addonsStock {
    width: fit-content;
    padding: 8px 15px;
    border-radius: 5px;
    background: var(--secondary);
    color: #fff;
    margin-top: 10px;
}

.btn--delete {
    background: #d83b27;
    padding: 8px 8px;
    margin-left: 10px;
}

.btn--positive {
    background: green;
}

.admin__main__notification__item__col .info {
    margin-top: 8px;
    font-size: 13px;
    font-style: italic;
}

.admin__order__cart__item__details__addon {
    margin-bottom: 5px;
}

.admin__main__top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.btn--sort {
    padding: 5px 15px;
    margin: 0 15px;
    height: 40px;
    border-radius: 5px;
}

.admin__main__top .admin__main__header {
    margin-right: 2vw;
}

.admin__main__top__finder {
    width: 100%;
    margin-top: 20px;
    font-size: 14px;
}

.admin__main__top__finder .input {
    margin-top: 8px;
    width: 300px;
    max-width: 100%;
    height: 40px;
}

.orderStatus__text__timestamp {
    display: block;
    font-size: 13px;
    font-style: italic;
}

.rdw-center-aligned-block * {
    text-align: center !important;
}

.rdw-right-aligned-block * {
    text-align: right !important;
}

.rdw-left-aligned-block * {
    text-align: left !important;
}

.rdw-justify-aligned-block * {
    text-align: justify !important;
}

.admin__faq__section {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 3px solid var(--secondary);
}

.admin__faq__section__questions:not(:last-of-type) {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px dashed var(--secondary);
}

.btn--addSection {
    padding: 5px 15px;
    border-radius: 5px;
    height: 40px;
    margin-left: 20px;
}

.admin__main__header--faq {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.admin__faq__section__headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 900px;
}

.admin__faq__section__header__label {
    width: 70%;
    margin-right: 0 !important;
}

.btn--deleteQuestion {
    width: 40px;
    height: 40px;
    font-size: 30px;
    border-radius: 50%;
}

.btn--deleteQuestion:hover {
    background: #d83b27;
    filter: brightness(.8);
}

.admin__faq__section__header__buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
}

.btn--addQuestion.btn--delete {
    margin-top: 15px;
}

.btn--addQuestion.btn--delete:hover {
    background: #d83b27;
    filter: brightness(.9);
}

.admin__faq .input--textarea {
    padding: 10px 15px;
    height: 200px !important;
    max-height: 300px;
}

.container--adminLogin .info--error {
    margin: 20px auto;
}

.admin__login__subheader {
    margin-bottom: 40px;
}

.rdw-link-decorator-wrapper img {
    display: none !important;
}

.withInvoice span:last-of-type {
    font-weight: 700;
    padding-top: 10px;
}

.m2 {
   padding-left: 40px;
}

.page__content--warranty * {
    margin-top: 0;
    margin-bottom: 0;
}

.btn--half {
    margin-top: 40px;
    width: 48%;
    font-size: 17px;
    border-radius: 40px;
}

.newsletterButtons > .flex {
    justify-content: space-between;
}

.newsletter__mails__item {
    padding-bottom: 40px;
    margin-bottom: 30px;
    border-bottom: 1px solid var(--secondary);
}
