@media(max-width: 1600px) {
    .panelMenu__submenu__link,
    .panelMenu__list__item__link {
        font-size: 14px;
    }

    .orderStatus__text {
        font-size: 15px;
        flex-basis: 75%;
    }

    .orderStatus__number {
        width: 40px;
        height: 40px;
    }

    .admin__order__right__header {
        font-size: 17px;
    }

    .admin__order__cart *,
    .admin__order__cart__header *,
    .admin__order__right__text span {
        font-size: 14px;
    }

    .admin__order__cart__item__details__addons:first-child {
        margin-right: 10px;
    }

    .btn--orderStatus {
        font-size: 12px;
    }
}

@media(max-width: 1400px) {
    .singleOption {
        /*transform: scale(.85);*/
        /*transform-origin: top left;*/
    }

    .btn--admin--type {
        width: 100%;
    }

    .singleOption__number {
        display: none;
    }

    .singleOption__top .btn--addOption {
        margin-left: 0;
    }

    .container--addProduct label {
        margin-right: 0;
    }

    .container--addAddon label {
        margin-right: 20px;
    }

    .admin__main__order {
        flex-direction: column;
    }

    .admin__main__order > * {
        width: 100%;
        border-right: none;
    }

    .orderStatus:first-of-type {
        margin-top: 0;
    }

    .admin__order__left {
        padding-right: 0;
        margin-bottom: 30px;
    }

    .admin__order__right {
        padding-left: 0;
    }
}

@media(max-width: 1290px) {
    .dzu-input {
        margin-left: 0;
    }

    .panelMenu {
        width: 25%;
    }

    .admin__main {
        width: 75%;
        margin-left: 25%;
    }

    .panelMenu__list__item__link {
        padding: 10px 20px;
    }
}

@media(max-width: 1200px) {
    .admin__main__header {
        font-size: 19px;
    }

    .admin__start {
        margin-top: 25px;
    }

    .contactWithSupportText {
        font-size: 12px;
    }

    .admin__start__section__header {
        font-size: 14px;
    }

    .admin__start__stats__value {
        font-size: 24px;
    }

    .admin__start__stats__key {
        font-size: 10px;
    }

    .btn--admin {
        font-size: 15px;
    }
}

@media(max-width: 996px) {
    .container--addProduct label {
        margin-right: 0;
    }

    .addProduct__addonsSection__main__item {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .addProduct__addonsSection__main__itemWrapper:not(:last-child) {
        border-bottom: 1px dashed var(--secondary);
        padding-bottom: 10px;
    }

    .addProduct__addonsSection__main__item label:first-of-type {
        width: 80%;
        max-width: 110px;
        order: 1;
    }

    .addProduct__addonsSection__input {
        width: 30px;
        height: 30px;
    }

    .addProduct__addonsSection__labelForInput {
        margin-top: 0;
        margin-bottom: 0;
    }

    .addProduct__addonsSection__label span {
        order: 10;
    }

    .addProduct__addonsSection__main__item label:last-of-type {
        order: 3;
        width: 100%;
    }

    .addProduct__addonsSection__main__item button {
        order: 2;
    }

    .admin__main__subheader {
        font-size: 14px;
    }

    .admin__form {
        flex-direction: column;
    }

    .admin__form > * {
        width: 100%;
    }

    .admin__form__section:first-of-type {
        border-right: none;
        border-bottom: 1px solid var(--secondary);
    }

    .btn--addOption {
        width: 35px;
        height: 35px;
        max-width: 35px;
        margin-left: 15px;
        font-size: 28px;
    }

    .singleOption {
        flex-direction: column;
        align-items: flex-start;
        border-bottom: 1px dotted var(--secondary);
    }

    .singleOption:last-of-type {
        margin-bottom: 20px;
    }

    .dzu-input {
        margin-left: -185px;
    }

    .btn--admin {
        height: 45px;
        font-size: 17px;
    }

    .singleOption > label {
        margin-top: 10px;
    }

    .btn--choose {
        font-size: 13px;
    }

    .admin__start__section {
        width: 100%;
        margin: 0 0 20px 0 !important;
    }

    .d-900 {
        display: none;
    }

    .scroll::-webkit-scrollbar-button,
    .scroll::-webkit-scrollbar {
        width: 2px;
        height: 2px;
    }

    .panelMenu {
        top: 70px;
        bottom: unset;
        height: calc(100% - 70px);
        width: 60px;
    }

    .panelMenu__list__item__link {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .panelMenu__submenu__link--special {
        justify-content: center;
        position: relative;
    }

    .panelMenu__list__item__icon~.panelMenu__submenu__link--special  {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .panelMenu__list__item__icon {
        margin-right: 0;
    }

    .panelMenu__list {
        padding-top: 0;
    }

    .admin__main {
        width: calc(100% - 80px);
        margin-left: 80px;
        padding-left: 5px;
        margin-top: 70px;
    }

    .admin__main__notification__item__btn {
        margin: 15px 8px 0;
        width: 15px;
    }

    .admin__main__notification__item__key {
        font-size: 12px;
    }

    .admin__main__notification__item__value {
        font-size: 14px;
    }

    .adminTop {
        height: 70px;
    }

    .container--addProduct label {
        font-size: 10px;
        margin-bottom: 10px;
        margin-top: 20px;
        max-width: 100%;
    }

    .container--addProduct .addProduct__addonsSection__condition label {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .container--addProduct .input {
        max-width: 100%;
        height: 40px;
        font-size: 12px;
    }

    .addProduct__addonsSection__header {
        font-size: 15px;
    }

    .rug .rug-handle {
        height: 240px !important;
    }

    .rug .rug-handle .rug-handle-info .rug-handle-button {
        max-width: 80% !important;
        font-size: 13px !important;
    }

    .rug .rug-handle svg.rug-handle-icon {
        width: 40px !important;
    }

    .btn--admin--type {
        margin-top: 25px;
    }

    .admin__status {
        position: relative;
        left: 0;
        top: 0;
        margin: 25px 0;
        display: block;
        font-size: 12px;
    }

    .admin__status span {
        width: 100%;
        display: block;
        text-align: center;
    }

    .adminBlog__images .editor__videoWrapper__placeholderContent__text,
    .editorWrapper__header,
    .admin__status span {
        font-size: 12px;
    }

    .editor__videoWrapper__icon {
        width: 40px;
    }

    .oldImgWrapper,
    .admin__label__imgUpload {
        height: 200px;
    }

    .colorModal__header {
        font-size: 15px;
    }

    .colorModal__text {
        font-size: 12px;
        margin: 5px 0;
    }

    .colorModal__inner {
        height: 100vh;
        max-height: 500px;
    }

    .container--admin > .colorModal .colorModal__inner {
        max-height: 300px;
    }

    .newsletterList__item {
        font-size: 14px;
    }

    .editor__videoWrapper__placeholderContent__text {
        font-size: 12px;
    }

    .singleOption {
        margin-top: 10px;
        margin-bottom: 0;
    }

    .optionsHeader {
        margin-bottom: 10px;
    }

    .admin__form__field__openInNewTabBtn {
        display: block;
        padding-left: 0;
        padding-top: 5px;
    }

    .admin__form__field {
        margin-bottom: 10px;
    }

    .panelMenu {
        z-index: 500;
    }

    .modal__link {
        font-size: 10px;
    }

    .modal .modal__inner {
        padding: 40px 10px;
    }
}

@media(max-width: 776px) {
    .admin__main {
        padding-top: 20px;
    }

    .admin__login {
        width: 90%;
        max-width: 400px;
    }

    .admin__login .input {
        height: 40px;
        max-width: 100%;
    }

    .admin__login__logo {
        width: 70%;
    }

    .admin__login__header {
        font-size: 19px;
        margin: 30px auto 20px;
    }

    .admin__login__subheader {
        font-size: 14px;
        text-align: center;
        font-weight: 400;
    }

    .admin__main__top {
        flex-direction: column;
    }

    .btn--sort {
        margin: 10px 0;
        font-size: 12px;
        height: 35px;
    }

    .admin__main__top__finder {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 13px;
    }

    .admin__main__backBtn {
        position: relative;
        margin-top: 20px;
        font-size: 12px;
        display: block;
        width: 100%;
    }

    .btn--downloadZip {
        font-size: 13px;
        height: 35px;
    }

    .btn--admin--contact {
        font-size: 10px;
    }

    .adminTop__logoWrapper {
        width: 100px;
    }

    .siteHeader__player__btn--profile {
        width: 40px;
        height: 40px;
        margin-right: 0;
    }

    .admin__main__notification__item {
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 10px 0;
    }

    .admin__main__notification__item:first-of-type {
        margin-top: 25px;
    }

    .admin__main__notification__item__col {
        width: 48%;
        margin-bottom: 25px;
        flex-basis: 48% !important;
    }

    .admin__main--addonList .admin__main__notification__item__col {
        flex-basis: 100% !important;
        width: 100%;
    }

    .addProduct__addonsSection__label span {
        font-size: 12px;
    }

    .adminBlog__images,
    .flex--addonType {
        flex-direction: column;
    }

    .adminBlog__images > div {
        width: 100%;
        margin-right: 0 !important;
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .addProduct__addonsSection__addonOptions span,
    .addProduct__addonsSection__addonOptions i {
        font-size: 11px;
    }

    .flex--addonType .btn--choose {
        width: 100%;
        margin-bottom: 0;
        margin-right: 0;
    }

    .admin__order__cart__item__details,
    .admin__order__right > .flex {
        flex-direction: column;
    }

    .admin__order__cart__item__details > * {
        width: 100%;
        margin-bottom: 20px;
    }

    .admin__order__right > .flex > div:first-of-type {
        margin-bottom: 20px;
    }

    .admin__order__cart {
        margin-top: 30px;
    }

    .admin__order__cart__item > span,
    .admin__order__cart__header > span {
        font-size: 11px;
    }

    .admin__order__cart__item__details__addons {
        margin-right: 0 !important;
    }

    .admin__order__cart__item__details__addon {
        padding-left: 0;
        font-size: 12px;
    }

    .admin__order__cart__item__details__btn {
        font-size: 12px;
    }

    .profileMenu {
        right: -7px;
        transform: translate(0, 100%) scale(.7);
        transform-origin: top right;
    }
}

@media(max-width: 576px) {
    .admin__main__header {
        font-size: 17px;
    }

    .admin__contact__textarea {
        font-size: 12px;
    }

    .admin__start__stats {
        width: 48%;
    }

    .btn--admin {
        font-size: 12px;
    }

    .admin__label__imgUpload {
        width: 100%;
        max-width: 300px;
    }

    .orderStatus__text {
        font-size: 13px;
        margin-left: 20px;
    }

    .orderStatus__number {
        width: 30px;
        height: 30px;
        font-size: 17px;
    }

    .admin__order__right__header {
        font-size: 16px;
    }

    .admin__order__right__text > span {
        font-size: 12px;
    }

    .admin__order__cart__item__btn > .btn__img {
        width: 15px;
    }

    .info {
        font-size: 13px;
    }
}
