@media(max-width: 1600px) {
    .orderDetails__header {
        font-size: 32px;
    }
}

@media(max-width: 1400px) {
    .cart__table__amountChange {
        max-width: 90px;
        height: 40px;
    }

    .post {
        max-width: 90vw;
    }

    .blog__content__item__header {
        font-size: 21px;
    }

    .blog__content__item__btn {
        font-size: 13px;
    }

    .panel__header,
    .orderDetails__header {
        font-size: 26px;
    }

    .orderDetails__bottom__section__header,
    .orderDetails__bottom__item__content__header {
        font-size: 18px;
    }

    .orderDetails__mainInfo__section *,
    .orderDetails__bottom__item__content__price {
        font-size: 15px;
    }

    .orderDetails__bottom__section p,
    .orderDetails__bottom__item__addon {
        font-size: 13px;
    }

    .btn--orderDetails,
    .footer__col__header {
        font-size: 15px;
        margin-bottom: 15px;
    }

    .footer__col > span,
    .footer__link {
        font-size: 14px;
        margin: 15px 0;
    }

    .homepage__header {
        font-size: 48px;
    }

    .homepageModels__item {
        margin-top: 30px;
    }

    .homepageModels__item__header {
        font-size: 15px;
    }

    .afterSlider__item__header {
        font-size: 26px;
    }

    .order__header__h1 {
        font-size: 28px;
    }

    .order__header {
        margin: 40px auto 70px;
    }

    .order__header__steps__item {
        font-size: 16px;
    }

    .shipping__header,
    .cart__summary__summaryRow > span,
    .cart__summary__header {
        font-size: 15px;
    }

    .shipping__method__name,
    .shipping__method__price,
    .cart__summary__row span {
        font-size: 14px;
    }

    .btn--cart {
        font-size: 16px;
        height: 50px;
    }

    .shipping__method__circle {
        width: 15px;
        height: 15px;
        flex-basis: 100%;
        max-width: 15px;
    }

    .shipping__method__circle::after {
        width: 6px;
        height: 6px;
    }
}

@media(max-width: 1200px) {
    .productDetails {
        max-width: 90vw;
    }

    .footer .contact__top__section__header {
        text-align: center;
        font-size: 15px;
        margin-top: 15px;
    }

    .footer .contact__top__section__data {
        text-align: center;
    }

    .footer__col > .footer__phoneNumber {
        margin-top: 0;
    }

    .footer__logo {
        transform: none;
    }

    .addon__option__tooltip {
        width: 200px;
    }

    .faq .pageHeader {
        font-size: 24px;
    }

    .faq__section__header {
        font-size: 19px;
    }

    .faq__section__question__question {
        font-size: 15px;
    }

    .faq__section__question__answer {
        line-height: 24px;
    }

    .blog__header,
    .contact__top__section__header {
        font-size: 21px;
    }

    .contact__bottom label {
        margin: 30px auto;
    }

    .contact__bottom__header,
    .formPage .pageHeader {
        font-size: 24px;
    }

    .formSection__header,
    .formPage__info * {
        font-size: 17px;
    }

    .formEnd,
    .formPage .btn--submit {
        font-size: 15px;
        max-width: 300px;
    }

    .formEnd {
        max-width: 90vw;
    }

    .post__article {
        margin-bottom: 50px;
    }

    .post__bottom {
        margin-top: 0;
    }

    .post__bottom__btn > span:first-of-type {
        font-size: 16px;
    }

    .post__bottom__btn > span:last-of-type {
        font-size: 13px;
    }

    .blog__meta *,
    .formPage__label,
    .formSection__img figcaption,
    .formSection__subheader {
        font-size: 14px;
    }

    .ordersTable * {
        font-size: 13px !important;
    }

    .cart__table__cell--1 {
        flex-basis: 20%;
    }

    .pageHeading {
        font-size: 28px;
    }

    .shop__products__item__notAvailable {
        font-size: 15px;
    }

    .shop__products__item__header {
        font-size: 18px;
    }

    .shop__products__item__price {
        font-size: 15px;
    }

    .shop__products__item__btn {
        width: 200px;
        height: 38px;
        margin-top: 40px;
        font-size: 15px;
    }

    .shop__products__item__btn--notAvailable {
        width: 300px;
        margin-top: 60px;
    }

    .footer__inner {
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .footer__col {
        width: 100%;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .footer__col > span,
    .footer__link, .footer__col__header {
        text-align: center;
        width: 100%;
        max-width: 250px;
        margin: 10px 0;
    }

    .footer__socialMedia {
        margin-top: 15px;
    }

    .footer {
        padding: 20px 0;
    }

    .topHeader__firstRow__link {
        width: 250px;
    }

    .topHeader__menu__item {
        font-size: 14px;
    }
}

@media(max-width: 996px) {
    .order__main {
        flex-direction: column;
    }

    .addon__title,
    .addon__options {
        justify-content: center;
    }

    .addon__title {
        flex-direction: column;
    }

    .addon__title__infoBtn {
        margin: 10px 0 20px 0;
    }

    .shop__header,
    .filterSection,
    .shop__products {
        max-width: 90%;
    }

    .shop__products__item {
        width: 40vw;
        height: calc(40vw / 0.666666);
    }

    .info--error--order {
        text-align: center;
    }

    .product .info--error {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .blog__content__item__header {
        font-size: 17px;
    }

    .btn--editData {
        font-size: 15px;
    }

    .orderDetails__mainInfo {
        flex-wrap: wrap;
        padding: 5px 20px;
    }

    .orderDetails__mainInfo__section {
        width: 48%;
        margin: 15px 0;
    }

    .product {
        flex-direction: column;
    }

    .product > * {
        width: 100%;
    }

    .product__gallery__main {
        width: 100%;
    }

    .product__gallery__main__figure {
        width: 75%;
        margin: auto;
    }

    .product__gallery__btn:hover {
        background: transparent;
    }

    .product__gallery__btn--next {
        right: -8px;
    }

    .product__gallery__btn--prev {
        left: -8px;
    }

    .product__gallery__btn {
        background: transparent;
    }

    .addon__title,
    .product__price,
    .product__title {
        text-align: center;
    }

    .product__title {
        font-weight: 600;
        font-size: 24px;
    }

    .addon__title {
        font-size: 17px;
    }

    .product__price {
        font-size: 16px;
        margin: 10px auto;
    }

    .product__shortDesc {
        font-size: 13px !important;
        margin-top: 25px;
    }

    .productDetails {
        margin-bottom: 50px;
    }

    .productDetails > div *,
    .product__shortDesc * {
        font-size: 13px !important;
    }

    .product__content {
        margin-top: 40px;
    }

    .product__gallery {
        position: relative;
        top: unset;
    }

    .cart__summary--mobileWrapper {
        margin: -30px 0 30px;
    }

    .order__main--orderReceived .cart__summary--mobileWrapper {
        margin-top: 50px;
    }

    .btn--backToCart {
        font-size: 16px;
    }

    .btn--order {
        font-size: 16px;
    }

    .d-from-900 {
        display: none !important;
    }

    .order__main > * {
        width: 100%;
    }

    .afterSlider > .flex {
        flex-direction: column;
    }

    .homepage__header {
        text-align: center;
        font-size: 36px;
    }

    .homepageModels__item__header {
        height: 50px;
        border-radius: 50px;
    }

    .afterSlider {
        padding: 40px 0 20px;
    }

    .newsletter__header {
        font-size: 21px;
    }

    .newsletter__subheader {
        font-size: 16px;
    }

    .productContent__info {
        margin: auto;
        text-align: justify;
    }

    .contact .info {
        margin-top: 20px;
    }

    .btn--productContent {
        margin: 30px auto;
    }

    .productDetails {
        margin-top: 30px;
    }

    .product {
        margin-bottom: 20px;
    }

    .productDetails__header {
        margin: 0 auto 30px;
    }
}

@media(max-width: 768px) {
    .d-desktop {
        display: none !important;
    }

    #termsOfService .indent {
        padding-left: 10px !important;
    }

    .page table {
        transform-origin: top left;
        transform: scale(0.65);
    }

    .page table:first-of-type {
        margin-bottom: -212px;
        transform: scale(0.82);
    }

    .page table:nth-of-type(2) {
        margin-bottom: -1750px;
    }

    .page table:nth-of-type(3) {
        transform: scale(0.85);
        margin-bottom: -110px;
    }

    .page table:nth-of-type(4) {
        margin-bottom: -1620px;
        transform: scale(0.695);
        border-top: 2px solid #fff !important;
        margin-top: 0;
    }

    .page table:nth-of-type(5) {
        margin-bottom: -200px;
        transform: scale(0.854);
    }

    .page table:nth-of-type(6) {
        margin-bottom: -300px;
        transform: scale(0.736);
    }

    .page table:last-of-type {
        transform: scale(0.4);
        margin-bottom: -4340px;
    }

    .instruction__row:first-child {
        margin-bottom: 10px;
    }

    .page__images img {
        margin: 0 5px;
    }

    .page table * {
        margin-left: 0 !important;
        text-indent: 0 !important;
        text-align: left !important;
    }

    .m1 {
        padding-left: 8px;
        max-width: 90% !important;
    }

    .page__link {
        font-size: 12px !important;
        padding: 7px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .instruction__row {
        flex-direction: column;
    }

    .instruction__row .img {
        width: 80%;
        max-width: 100px;
    }

    .instruction__row .img--icon {
        transform: rotate(90deg);
        margin: 40px auto;
    }

    .homepageModels .slick-slide > div {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .formSection__checkbox--newsletter {
        max-width: 80% !important;
    }

    .footer__logo {
        max-width: 140px;
    }

    .footer__phoneNumber {
        margin-top: 6px !important;
    }

    .footer__phoneNumber > span {
        font-size: 12px;
    }

    .post__mainImg {
        max-width: 100%;
    }

    .post__mainImg .img {
        border-radius: 15px;
    }

    .contact__bottom .formSection__checkbox .form__addons__label {
        margin: 0;
    }

    .contact__bottom .formSection__checkbox .form__addons__label span {
        font-size: 12px;
    }

    .singleOption__top {
        justify-content: center;
    }

    .flex--mobile {
        flex-direction: column;
    }

    .example__item {
        width: 90%;
        margin-bottom: 55px;
    }

    .example__item:last-of-type {
        margin-bottom: 0;
    }

    .example__header {
        font-size: 16px;
    }

    .newPasswordForm .shipping__header {
        margin-top: 40px;
        max-width: 90%;
    }

    .newsletterPage .btn--remindPassword--success {
        margin-bottom: 40px;
    }

    .addon__option__imageType > span {
        font-size: 13px;
    }

    .addons__header {
        text-align: center;
        font-size: 21px;
    }

    .orderDetails__statuses .orderStatus:not(:last-child)::before {
        display: none !important;
    }

    .orderDetails__bottom__item {
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }

    .orderDetails__bottom .orderDetails__header {
        margin-bottom: 40px;
    }

    .orderDetails__bottom__item__figure {
        max-width: 150px;
        margin-right: 0;
    }

    .orderDetails__bottom__item > * {
        width: 100%;
    }

    .orderDetails__bottom__item__content {
        margin-top: 20px;
    }

    .colorModal--waitlist .colorModal__inner {
        max-width: 90%;
        padding: 40px 20px;
        height: 340px;
    }

    .colorModal--waitlist .info--success {
        font-size: 14px;
    }

    .post__article img {
        max-width: 75vw;
        margin: auto;
    }

    .page__content > figure {
        max-width: 70vw;
        margin: 15px auto;
    }

    .blog__header {
        margin: 30px auto 20px;
        font-size: 21px;
    }

    .payment .shipping__method__circle {
        margin-right: 12px;
    }

    .shipping__method--selected .shipping__method__circle::after {
        width: 7px;
        height: 7px;
    }

    .formEnd,
    .payment .btn--sendForm {
        font-size: 14px;
    }

    .payment .pageHeader {
        margin-top: 25px;
        font-size: 21px;
    }

    .shipping__method__name {
        text-align: left;
        font-size: 12px;
    }

    .shipping__method__price {
        font-size: 12px;
    }

    .payment .payment__text {
        font-size: 15px;
        font-weight: 400;
    }

    .shop__products__item__notAvailable,
    .shop__products__item__btn {
        font-size: 14px;
    }

    .shop__products__item__header {
        font-size: 17px;
    }

    .form .input {
        margin: 8px 0;
    }

    .form__passwordReminder {
        font-size: 12px;
    }

    .emptyCart {
        margin: 40px auto;
    }

    .emptyCart__header {
        font-size: 21px;
    }

    .btn--backToShop {
        height: 45px;
        width: 250px;
        font-size: 14px;
        font-weight: 600;
        max-width: 80vw;
    }

    .page {
        margin-top: 40px;
    }

    .page__content * {
        font-size: 14px;
    }

    .page .pageHeading {
        text-align: center;
    }

    .faq .pageHeader {
        font-size: 21px;
        margin-top: 30px;
        text-align: center;
    }

    .faq__section__header {
        text-align: center;
    }

    .faq__section__question__question {
        font-size: 14px;
        margin-bottom: 15px;
    }

    .faq__section__question__answer {
        font-size: 12px;
    }

    .fullScreenGallery .slick-prev {
        left: 0;
    }

    .fullScreenGallery .slick-next {
        right: 0;
    }

    .fullScreenGallery__inner {
        width: 80vw;
        height: auto;
    }

    .fullScreenGallery__inner .img {
        width: 100%;
        height: auto;
    }

    .fullScreenGallery .slick-next,
    .fullScreenGallery .slick-prev {
        background: transparent;
    }

    .fullScreenGallery .slick-next::before,
    .fullScreenGallery .slick-prev::before {
        width: 90px;
        transform: scale(1.5);
    }

    .fullScreenGallery__close {
        top: 0;
        right: 10px;
        font-size: 48px;
    }

    .emptyOrderList .shipping__header {
        text-align: center;
    }

    .verificationPage {
        min-height: 1px;
    }

    .verificationPage .btn--verification {
        margin-bottom: 40px;
    }

    .remindPassword {
        margin: 10px auto 40px;
    }

    .remindPassword label,
    .remindPassword .input {
        max-width: 90vw;
    }

    .remindPassword .input {
        height: 45px;
    }

    .remindPassword__text,
    .remindPassword label {
        font-size: 14px;
    }

    .btn--remindPassword {
        font-size: 15px;
    }

    .btn--remindPassword,
    .waitlistLabel .input,
    .waitlistLabel {
        max-width: 90vw;
    }

    .waitlistLabel .input {
        height: 45px;
    }

    .blog__content {
        grid-template-columns: 1fr;
        grid-column-gap: 0;
        grid-row-gap: 60px;
    }

    .blog__pagination {
        max-width: 75vw;
    }

    .blog__pagination__pages__btn {
        margin: 0 10px;
        font-size: 15px;
    }

    .blog__content__item__imgWrapper {
        border-radius: 15px;
    }

    .blog__content__item__header {
        font-size: 17px;
    }

    .blog__content__item__excerpt {
        font-size: 12px;
        margin-top: 8px;
    }

    .post .blog__header {
        font-size: 18px;
    }

    .blog__header {
        text-align: center;
    }

    .contact__top {
        flex-direction: column;
        margin: 20px auto;
    }

    .contact__top__section__header {
        font-size: 18px;
        margin-bottom: 15px;
    }

    .contact {
        margin-top: 20px;
    }

    .contact__bottom {
        margin: 20px auto;
    }

    .contact__bottom__header {
        font-size: 21px;
    }

    .blog__meta {
        margin: -10px 0 5px;
        justify-content: center;
    }

    .blog__meta p {
        margin: 0 15px;
        font-size: 12px;
    }

    .post__bottom__btn {
        max-width: 45%;
    }

    .post__bottom__btn > span:first-of-type {
        font-size: 12px;
    }

    .post__bottom__btn > span:last-of-type {
        font-size: 11px;
    }

    .post__mainImg {
        margin: 20px auto;
    }

    .post__mainImg,
    .post__article img {
        border-radius: 15px;
    }

    .contact__bottom .input--textarea {
        height: 150px !important;
    }

    .post__article *,
    .contact__bottom label,
    .contact__top__section__data {
        font-size: 14px;
    }

    .contact__bottom .input {
        height: 45px;
    }

    .contact__top__section:not(.footer .contact__top__section) {
        margin-bottom: 40px;
    }

    .footer .contact__top__section__header {
        font-size: 14px;
    }

    .footer .contact__top__section__data {
        font-size: 12px;
    }

    .input--rendered {
        max-height: 100px;
        margin: 0 4px;
    }

    .label--withW100Input {
        margin-bottom: 130px !important;
    }

    .formSection__imagesMultiple {
        flex-direction: column;
    }

    .formSubmitted__header {
        font-size: 18px;
    }

    .formSection__checkbox {
        overflow-x: hidden;
    }

    .formSection__checkbox > label span {
        font-size: 14px;
    }

    .formSection__checkbox > label > span {
        max-width: calc(100% - 40px);
    }

    .formPage .pageHeader {
        font-size: 18px;
    }

    .formSection__header--formType2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .formSection__checkbox,
    .formSection__checkbox > label {
        max-width: 100%;
    }

    .formPage__info * {
        font-size: 14px;
    }

    .formSection {
        flex-direction: column;
        padding: 20px 0;
    }

    .btn--sendForm {
        font-size: 15px;
        max-width: 80vw;
    }

    .formEnd {
        margin-top: 5px;
    }

    .formSection > * {
        width: 100%;
    }

    .formSection__header {
        order: 1;
    }

    .formSection__subheader {
        order: 2;
    }

    .formSection__img {
        order: 3;
        position: relative;
        top: unset;
    }

    .formSection__content {
        order: 4;
    }

    .panel__main--start__header,
    .panel__main--start__text {
        font-size: 14px;
        text-align: center;
    }

    .ordersTable__row {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .ordersTable > div > * {
        flex-basis: 100% !important;
        margin-bottom: 20px;
    }

    .btn--orderList {
        width: 400px;
        max-width: 75vw;
        height: 45px;
    }

    .panel {
        flex-direction: column;
        margin-top: 30px;
    }

    .panel > * {
        width: 100%;
    }

    .orderDetails__header {
        font-size: 21px;
    }

    .panel__menu {
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .panel__menu__menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 400px;
    }

    .panel__header {
        margin-bottom: 10px;
    }

    .panel__menu__item--selected::after {
        display: none;
    }

    .panel__menu__item {
        margin: 12px 0;
    }

    .productDetails > div * {
        font-size: 14px;
    }

    .addon__option {
        margin-bottom: 25px !important;
        max-width: 160px;
    }

    .addon__option__textType {
        font-size: 12px;
    }

    .addon__option--selected .addon__option__imageType > span::after {
        height: 1px;
    }

    .addon__options {
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .addon__option {
        margin: 0;
    }

    .product {
        margin-top: 30px;
    }

    .order__main--orderReceived {
        margin-top: -30px;
    }

    .orderReceived__header {
        font-size: 15px;
    }

    .orderReceived__header > span {
        font-size: 17px;
    }

    .orderReceived__info,
    .info--error {
        font-size: 14px;
    }

    .cart__table {
        margin-top: -50px;
    }

    .cart__table__cell--0 {
        flex-basis: 100%;
    }

    .cart__table__cell--0 .d-mobile {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .cart__table__cell--0 .d-mobile > * {
        width: 100%;
        flex-basis: 100%;
        margin: 8px 0;
    }

    .btn--orderReceived {
        height: 45px;
        border-radius: 45px;
        font-size: 15px;
    }

    .shipping__method {
        padding-left: 0;
    }

    .cart__summary {
        padding: 20px;
    }

    .order__userData__data {
        flex-direction: column;
    }

    .order__userData__data > * {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-bottom: 30px;
    }

    .order__userData__text {
        margin-bottom: 20px;
    }

    .order__userData__data .form__addons__label {
        margin: 10px 0;
    }

    .order__loginForm__header {
        font-size: 16px;
    }

    .order__userData__data * {
        font-size: 14px;
    }

    .shipping__method__img {
        width: 50px;
    }

    .orderForm label {
        font-size: 12px;
    }

    .input--order {
        height: 45px;
    }

    .cart__table__row {
        flex-wrap: wrap;
    }

    .order__header__h1 {
        text-align: center;
        font-size: 22px;
    }

    .order__header__steps--mobile > span {
        display: block;
        width: 33.333%;
        text-align: center;
        font-weight: 700;
        font-size: 18px;
    }

    .shop__products__item__btn--notAvailable {
        font-size: 12px;
        width: 240px;
    }

    .footer__col > span,
    .footer__link, .footer__col__header {
        margin: 5px 0;
    }

    .footer__col__header {
        font-size: 14px;
    }

    .footer__col > span,
    .footer__link {
        font-size: 12px;
    }

    .footer__socialMedia {
        width: 60px;
    }

    .footer__socialMedia .topBar__socialMedia__link {
        width: 20px;
        height: 20px;
    }

    .homepageModels > .flex {
        flex-direction: column;
    }

    .homepageModels__item {
        width: 100%;
        max-width: 400px;
    }

    .homepageModels {
        padding: 40px 0;
    }

    .pageHeader {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 90;
        background: var(--primary);
    }

    .topHeader__menu--mobile__btn {
        position: relative;
        background: transparent;
        width: 20px;
        margin: 15px 0;
    }

    .mobileMenu {
        position: fixed;
        overflow-y: scroll;
        top: 0;
        left: 0;
        height: 100vh;
        background: var(--secondary);
        z-index: 100;
        transform-origin: top left;
        transform: scaleX(0);
        transition: none !important;
        width: 0;
        overflow-x: hidden;
        padding: 0;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .mobileMenu::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .mobileMenu {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    .mobileMenu > * {
        opacity: 0;
        transition: .2s opacity;
    }

    .mobileMenu__backBtn {
        width: 20px;
        background: transparent;
    }

    .langBtn {
        background: transparent;
        width: 25px;
        height: auto;
    }

    .mobileMenu__menu {
        margin: 40px 0;
    }

    .mobileMenu__menu__arrow {
        width: 20px;
    }

    .mobileMenu__menu__item {
        background: transparent;
        display: block;
        width: 100%;
        margin: 10px 0;
        padding: 10px 0;
        font-size: 16px;
        border-top: 1px solid #C9BC9D;
        transform-origin: center;
    }

    .mobileMenu__menu__item--btn {
        display: flex;
        margin: 0;
        padding: 0;
        border-top: none;
    }

    .mobileMenu__menu > .mobileMenu__menu__item:first-child:not(.mobileMenu__menu__item--btn) {
        padding-top: 14px;
    }

    .mobileMenu__menu > .mobileMenu__menu__item:last-child:not(.mobileMenu__menu__item--btn) {
        border-bottom: 1px solid #C9BC9D;
        padding-bottom: 14px;
    }

    .mobileMenu__menu__submenu {
        height: 0;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }

    .mobileMenu__menu__submenu {
        padding-left: 15px;
    }

    .mobileMenu .footer__col__header {
        text-transform: none;
        font-weight: 500;
        font-size: 18px;
        text-align: left;
    }

    .mobileMenu .topBar__socialMedia__link {
        height: 30px;
    }

    .mobileMenu .footer__socialMedia {
        width: 70px;
    }

    .topHeader__firstRow__link {
        width: 165px;
    }

    .slick-dots li button:before,
    .slick-dots li button,
    .slick-dots li {
        width: 12px;
        height: 12px;
        line-height: 12px;
    }

    .slick-dots {
        max-height: 80px;
    }

    .slick-active {
        border: none;
    }

    .homepage__header {
        font-size: 21px;
        max-width: 80vw;
        margin: auto;
    }

    .afterSlider__item figure {
        max-width: 50vw;
        margin: auto;
    }

    .afterSlider__item::after {
        display: none;
    }

    .afterSlider__item {
        margin: 40px auto 20px;
    }

    .afterSlider__item__header {
        font-size: 18px;
        margin-top: 8px;
    }

    .homepageModels__item {
        max-width: 70vw;
    }

    .input--newsletter {
        width: 100%;
        height: 45px;
    }

    .newsletter__form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 80vw;
    }

    .newsletter__form > * {
        height: 45px;
        width: 100%;
        max-width: 500px;
        margin-bottom: 10px;
    }

    .newsletter__header,
    .newsletter__subheader {
        max-width: 80vw;
    }

    .newsletter__header {
        font-size: 21px;
    }

    .newsletter__subheader {
        font-size: 15px;
        margin: 20px auto 30px;
    }

    .newsletterSection {
        padding: 40px 0 30px;
    }

    .loginAndRegister__header {
        margin: 40px auto;
    }

    .shop__products__item {
        width: 80vw;
        height: calc(80vw / 0.6666);
        margin: 0 auto 30px;
    }

    .shop__products__item__hover {
        opacity: 1;
    }

    .shop__header {
        margin-top: 20px;
        flex-direction: column;
    }

    .filterBtn {
        margin-top: 30px;
        font-size: 14px;
    }

    .filterSection {
        justify-content: center;
    }

    .filterSection__btn {
        margin-left: 10px;
        margin-right: 10px;
    }

    .shop__products__item__hover {
        padding: 20px;
    }

    .pageHeading {
        font-size: 18px;
    }

    .orderDetails__mainInfo {
        margin-top: 15px;
        border: none;
    }

    .orderDetails__mainInfo__section {
        margin: 4px 0;
    }

    .orderDetails__mainInfo__section__key {
        font-weight: 700;
    }

    .orderDetails__bottom__item__content__header {
        font-size: 15px;
        text-align: center;
    }

    .orderDetails__bottom__item__addon {
        font-size: 12px;
        margin: 3px 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 8px;
    }

    .orderDetails__bottom__item__content__price {
        font-size: 13px;
        text-align: center;
        margin: 10px 0 20px;
    }

    .orderDetails__bottom__section__header {
        font-size: 17px;
        margin-bottom: 10px;
    }

    .orderStatus__number::before {
        display: none;
    }

    .orderDetails__statuses {
        padding-left: 0;
    }

    .orderStatus__buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .orderDetails__statuses .orderStatus {
        margin: 15px auto;
    }

    .btn--orderDetails {
        min-width: 1px;
        width: 100%;
        max-width: 350px;
        font-size: 13px;
    }

    .orderDetails__statuses .orderStatus__text {
        margin-left: 0;
        text-align: center;
        flex-basis: 100%;
    }

    .orderDetails__statuses .orderStatus {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .orderDetails__bottom__section {
        margin: 20px auto;
    }

    .orderDetails__bottom__section * {
        text-align: center;
    }

    .orderDetails__bottom {
        margin: 50px auto 20px;
    }
}

@media(max-width: 576px) {
    .loginAndRegister__header {
        max-width: 200px;
    }

    .panel__main--data label {
        font-size: 14px;
    }

    .btn--editData {
        height: 50px;
        border-radius: 50px;
    }

    .panel__menu__menu {
        max-width: 85vw;
    }

    .panel__menu__item {
        font-size: 12px;
    }

    .orderDetails__mainInfo__section {
        width: 100%;
    }

    .consents .form__addons__label span,
    .form__addons__label {
        font-size: 12px;
        line-height: 21px;
    }

    .form {
        margin-top: 0;
        margin-bottom: 60px;
    }

    .btn--submit,
    .form .input {
        height: 45px;
    }

    .loginAndRegister__btn {
        font-size: 12px;
    }

    .loginAndRegister__btn--selected::after {
        bottom: -5px;
        height: 1px;
    }
}

@media(min-width: 768px) {
    .d-mobile {
        display: none !important;
    }

    .fullScreenGallery .slick-arrow:active,
    .fullScreenGallery .slick-arrow:active,
    .fullScreenGallery .slick-arrow:focus,
    .fullScreenGallery .slick-arrow:focus,
    .fullScreenGallery .slick-arrow:hover,
    .fullScreenGallery .slick-arrow:hover {
        background: var(--hover) !important;
    }

    .fullScreenGallery .slick-next::before,
    .fullScreenGallery .slick-prev::before {
        content: url("../img/arrow-left.svg");
        padding-bottom: 4px;
    }

    .fullScreenGallery .slick-next::before {
        content: url("../img/arrow-right.svg");
    }
}

@media(min-width: 996px) {
    .d-to-900 {
        display: none !important;
    }
}
