@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #fff;
    font-family: 'Montserrat', sans-serif !important;
}

:root {
    --primary: #053A26;
    --secondary: #b9a16b;
    --hover: #A8905A;
}

.container {
    background: var(--primary);
}

button {
    border: none;
    outline: none;
    cursor: pointer;
}

a {
    text-decoration: none;
}

ul {
    list-style-type: none;
}

.bold {
    font-weight: 700;
}

.notBold {
    font-weight: 400 !important;
}

.btn {
    background: var(--secondary);
    color: #fff;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    border: none;
    outline: none;
    cursor: pointer;
    transition: .2s all;
}

.btn:hover {
    background: var(--hover);
}

.input {
    border: 2px solid var(--secondary);
    padding: 5px 5px 5px 10px;
    color: #fff;
    outline: none;
    background: transparent;
    transition: .2s all;
}

.input--client {
    background: #fff;
    color: #000;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn__img {
    width: 100%;
    height: auto;
}

a {
    border: none;
    outline: none;
}

.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hidden {
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
    display: block;
    z-index: -100;
}

.w {
    width: 1200px;
    max-width: 90vw;
    margin-left: auto;
    margin-right: auto;
}

.w--extra {
    width: 1600px;
    max-width: 90vw;
    margin-left: auto;
    margin-right: auto;
}

.d-none {
    display: none !important;
}

.container {
    background: var(--primary);
}

.topBar {
    background: var(--secondary);
    height: 30px;
    padding: 8px 0;
}

.topBar__socialMedia {
    width: 60px;
    height: 100%;
}

.topBar__socialMedia__link {
    height: 100%;
    width: 40px;
    display: block;
    opacity: .8;
    transition: .2s all;
}

.topBar > .w {
    height: 100%;
}

.topBar__socialMedia__link .img {
    height: 100%;
    width: auto;
}

.topBar__socialMedia__link:hover {
    opacity: 1;
}

.topBar__languageSwitcher {
    position: relative;
    width: 150px;
    text-align: center;
    z-index: 3;
}

.topBar__languageSwitcher__currentLanguage {
    text-transform: uppercase;
    font-size: 12px;
    display: block;
    position: relative;
}

.topBar__languageSwitcher__currentLanguage span {
    position: relative;
    transform: translateX(-10px);
    display: inline-block;
}

.topBar__languageSwitcher__currentLanguage span::after {
    content: url("../img/arrow-down.svg");
    transform-origin: top center;
    position: absolute;
    right: -20px;
    top: 50%;
    height: 100%;
    transform: translateY(-50%);
    font-size: 12px;
}

.topBar__languageSwitcher__secondLanguage {
    position: absolute;
    bottom: -40px;
    left: 0;
    width: 100%;
    height: 40px;
    background: var(--secondary);
    text-transform: uppercase;
    font-size: 12px;
    opacity: 0;
    visibility: hidden;
    transition: .2s all;
}

.topBar__languageSwitcher__secondLanguage:hover,
.topBar__languageSwitcher__currentLanguage:hover ~
.topBar__languageSwitcher__secondLanguage {
    visibility: visible;
    opacity: 1;
}

.topHeader__firstRow {
    border-bottom: 1px solid var(--secondary);
    padding: 20px 0 0;
    position: relative;
}

.topHeader__firstRow__link {
    display: block;
    width: 260px;
    height: auto;
    transform-origin: center;
    transition: .2s all;
}

.topHeader__firstRow__link:hover {
    transform: scale(1.02);
}

.topHeader__firstRow__right {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 58px;
}

.topHeader__firstRow__right__link {
    display: block;
    width: 18px;
    opacity: .8;
    transition: .2s all;
}

.topHeader__firstRow__right__link ~ .topHeader__logout {
    display: none;
}

.topHeader__firstRow__right__link--logoutOnHover {
    position: relative;
}

.topHeader__firstRow__right__link--logoutOnHover::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 25px;
    height: 50px;
    transform: translate(-100%, -50%);
    background: transparent;
}

.topHeader__firstRow__right__link--logoutOnHover ~ .topHeader__logout {
    display: block;
    order: -1;
    color: #fff;
    padding: 5px 12px;
    background: var(--secondary);
    margin-left: -100px;
    font-size: 11px;
    border-radius: 3px;
    opacity: 0;
    z-index: -1;
    transition: .2s all;
}

.topHeader__logout:hover,
.topHeader__firstRow__right__link--logoutOnHover:hover ~ .topHeader__logout {
    opacity: 1;
    z-index: 2;
}

.topHeader__logout:hover {
    background: var(--hover);
}

.topHeader__firstRow__right__link:hover {
    opacity: 1;
}

.pageHeader {
    position: relative;
    z-index: 10;
}

.cartCounter {
    background: var(--secondary);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    font-size: 10px;
    font-weight: 700;
    position: absolute;
    bottom: -5px;
    right: -5px;
}

.topHeader__menu {
    justify-content: center;
    margin: 10px 0;
}

.topHeader__menu__item {
    text-transform: uppercase;
    color: var(--secondary);
    font-size: 14px;
    display: block;
    margin: 6px 20px;
    opacity: 1;
    transition: .2s all;
}

.topHeader__menu__item:hover {
    opacity: .8;
}

span.topHeader__menu__item {
    display: block;
    position: relative;
}

.container--home .topHeader__menu__submenu {
    border-top: 1px solid var(--secondary);
    padding-top: 8px;
}

.topHeader__menu__submenu {
    position: absolute;
    bottom: -16px;
    left: -10px;
    width: 300px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--primary);
    padding: 0 15px 15px;
    transform: translateY(100%);
    visibility: hidden;
    opacity: 0;
    transition: .2s all;
}

span.topHeader__menu__item:hover {
    opacity: 1;
}

span.topHeader__menu__item::after {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 150%;
    height: 20px;
    background: transparent;
}

.topHeader__menu__submenu .topHeader__menu__item {
    text-align: left;
    display: block;
    width: 100%;
}

span.topHeader__menu__item:hover .topHeader__menu__submenu,
.topHeader__menu__submenu:hover {
    opacity: 1;
    visibility: visible;
}

.homepageSlider {
    height: auto;
    overflow: hidden;
    position: relative;
}

.homepageSlider__item {
    display: block;
    width: 100%;
}

.slick-dots {
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 5%;
    width: auto !important;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transform: translateY(-50%);
    max-height: 120px;
}

.slick-arrow {
    display: none !important;
}

.slick-active {
    border: 1px solid #000;
    border-radius: 50%;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.slick-slide > div {
    width: 100%;
}

.slick-dots li button:before {
    width: 100% !important;
    height: 100% !important;
}

.homepageModels .slick-active {
    border: none;
}

/*.homepageModels,*/
.afterSlider {
    padding: 100px 0;
}

.homepage__header {
    color: var(--secondary);
    font-weight: 400;
    font-size: 48px;
}

.flex--afterSlider {
    justify-content: space-around;
    align-items: flex-start;
}

.afterSlider__item {
    max-width: 380px;
    margin-top: 150px;
    position: relative;
}

.afterSlider__item figure {
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
    overflow: hidden;
    width: 100%;
    position: relative;
}

.afterSlider__item::after {
    content: "";
    position: absolute;
    left: 50%;
    top: -70px;
    height: 160px;
    background: var(--secondary);
    width: 2px;
    transform: translateX(-50%);
    z-index: 3;
    transition: .3s all;
}

.afterSlider__item:hover::after {
    transform: translate(-50%, -20px);
}

.afterSlider__item__header {
    color: var(--secondary);
    font-size: 32px;
    font-weight: 400;
    margin-top: 20px;
    text-align: center;
}

.homepageModels__item {
    display: block;
    margin: 50px 0 30px;
    position: relative;
    padding: 0 20px;
}

.homepageModels__item figure {
    width: 100%;
    height: auto;
    border-radius: 30px;
    overflow: hidden;
}

.homepageModels__item__header {
    position: absolute;
    text-align: center;
    bottom: -10px;
    left: 50%;
    width: 80%;
    transform: translateX(-50%);
    background: #B9A16B;
    color: #000;
    height: 74px;
    border-radius: 74px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 24px;
    transition: .2s all;
}

.homepageModels .slick-slider {
    overflow: hidden;
}

.homepageModels__item:hover .homepageModels__item__header {
    transform: translate(-50%, -10px);
}

.newsletterSection {
    border-top: 1px solid var(--secondary);
    margin: 40px auto;
    padding: 80px 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.newsletter__header,
.newsletter__subheader {
    text-align: center;
    color: var(--secondary);
    font-weight: 400;
}

.newsletter__header {
    color: var(--secondary);
    font-size: 24px;
}

.newsletter__subheader {
    color: #fff;
    font-size: 15px;
    margin: 30px auto 40px;
}

.newsletter__form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 580px;
    margin: auto;
}

.input--newsletter {
    border: none;
    width: 450px;
    height: 50px;
    padding-left: 20px;
}

.btn--newsletter {
    width: 120px;
    height: 50px;
}

.footer {
    background: var(--secondary);
    width: 100%;
    padding: 50px 0;
}

.footer__inner {
    align-items: flex-start;
}

.footer__col {
    width: 18%;
}

.footer__col__header {
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 30px;
    font-size: 16px;
}

.footer__logo {
    transform: translateX(-18px);
    max-width: 100%;
}

.footer__link {
    font-size: 15px;
    display: block;
    margin: 25px 0;
}

.footer__link:hover {
    opacity: .8;
}

.footer__socialMedia {
    width: 80px;
}

.footer__socialMedia .topBar__socialMedia__link {
    display: block;
    width: 30px;
    height: 30px;
}

.footer__col > span {
    display: block;
    margin: 20px 0 30px;
}

.footer__col > .footer__phoneNumber {
    margin-top: -20px;
}

.footer__phoneNumber,
.footer__phoneNumber > span {
    font-size: 14px;
}

.footer .contact__top__section__header {
    font-size: 16px;
    margin-bottom: 7px;
}

.footer .contact__top__section__data {
    font-size: 14px;
}

.info {
    display: block;
    text-align: center;
}

.info--error {
    color: red;
}

.shop__header {
    max-width: 920px;
    margin: 50px auto 30px;
}

.pageHeading {
    color: var(--secondary);
    text-transform: uppercase;
    font-size: 32px;
}

.filterBtn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 15px;
    background: transparent;
    outline: none;
    color: var(--secondary);
}

.filterIcon {
    width: 20px;
    margin-right: 12px;
}

.shop__products {
    max-width: 920px;
    margin: 0 auto 40px;
    flex-wrap: wrap;
    transition: .2s all;
}

.shop__products__item {
    width: 420px;
    height: calc(420px / 0.66666);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 110px;
    border-radius: 30px;
    overflow: hidden;
}

.shop__products__item__imgWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    overflow: hidden;
}

.shop__products__item__imgWrapper .productImg {
    width: 100%;
    height: auto;
    border-radius: 30px;
}

.shop__products__item__header {
    color: #000;
    text-align: center;
    font-size: 21px;
    text-transform: uppercase;
    font-weight: 700;
}

.shop__products__item__price {
    font-size: 16px;
    font-weight: 400;
    margin-top: 20px;
    color: #000;
}

.shop__products__item__notAvailable {
    font-size: 15px;
    color: #D6001C;
    text-transform: uppercase;
    font-weight: 400;
    margin: 25px auto -30px;
    text-align: center;
}

.shop__products__item__btn {
    background: #B9A16B;
    width: 200px;
    height: 45px;
    border-radius: 30px;
    font-size: 14px;
    color: #000;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    font-weight: 500;
    transition: .2s all;
}

.shop__products__item__btn:hover {
    background: var(--hover);
}

.shop__products__item__btn--notAvailable {
    width: 350px;
}

.shop__products__item > *:not(figure) {
    position: relative;
    z-index: 3;
}

.shop__products__item__hover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: -5;
    opacity: 0;
    background: rgba(234, 234, 234, .8);
    transition: .5s all;
}

.shop__products__item:hover .shop__products__item__hover {
    opacity: 1;
    z-index: 5;
}

.loginAndRegister__header {
    max-width: 300px;
    margin: 80px auto 40px;
}

.loginAndRegister__btn {
    color: var(--secondary);
    text-transform: uppercase;
    font-size: 16px;
    background: transparent;
}

.loginAndRegister__btn--selected {
    position: relative;
}

.loginAndRegister__btn--selected::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -8px;
    width: 100%;
    height: 2px;
    background: var(--secondary);
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
    max-width: 90vw;
    margin: 50px auto;
}

.form .input {
    width: 500px;
    max-width: 90vw;
    height: 55px;
    margin: 12px 0;
}

.btn--submit {
    width: 100%;
    height: 55px;
    border-radius: 55px;
}

.form__addons {
    margin: 20px auto 30px;
    width: 100%;
}

.form__addons__label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
}

.form__check {
    position: relative;
    flex-basis: 20%;
    max-width: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17px;
    height: 17px;
    border: 1px solid var(--secondary);
    margin-right: 10px;
    background: transparent;
    padding: 0;
}

.form__check span {
    position: relative;
    display: block;
    margin: 0 !important;
    width: 9px !important;
}

.form__check--selected > span::after {
    content: "";
    position: relative;
    width: 9px;
    height: 9px;
    background: var(--secondary);
    display: block;
}

.form__passwordReminder {
    color: var(--secondary);
    font-size: 14px;
    text-decoration: underline;
}

.btn__fill {
    width: 10px;
    height: 10px;
    background: var(--secondary);
}

.form__additionalInfo {
    margin-top: 35px;
    flex-direction: column;
}

.form__additionalInfo__header {
    font-size: 14px;
    text-align: center;
}

.form__additionalInfo__btn {
    background: transparent;
    color: var(--secondary);
    text-decoration: underline;
    font-size: 14px;
    margin-top: 5px;
}

.form__additionalInfo__btn,
.form__passwordReminder {
    transition: .2s all;
}

.form__additionalInfo__btn:hover,
.form__passwordReminder:hover {
    opacity: .8;
}

.form .info--error {
    font-size: 14px;
    margin-bottom: 15px;
}

.consents .form__addons__label {
    align-items: flex-start;
    margin: 25px 0;
}

.consents .form__addons__label .form__check {
    flex-basis: 17px;
}

.consents .form__addons__label span {
    width: calc(100% - 17px);
    display: block;
    line-height: 24px;
    font-size: 14px;
    margin-top: -4px;
}

.form .input::placeholder {
    color: rgba(255, 255, 255, .8);
}

.form {
    margin-bottom: 150px;
}

.panel {
    align-items: flex-start;
    margin: 80px auto;
}

.panel__header {
    text-transform: uppercase;
    color: var(--secondary);
    font-size: 35px;
    margin-bottom: 40px;
}

.panel__menu__item {
    background: transparent;
    position: relative;
    font-size: 14px;
    color: var(--secondary);
    display: block;
    margin: 25px 0;
    text-transform: uppercase;
}

.panel__menu__item:hover {
    opacity: .8;
}

.panel__menu__item--selected {
    font-weight: 700;
}

.panel__menu__item--selected::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    background: var(--secondary);
}

.panel__main--start__header,
.panel__main--start__text {
    color: var(--secondary);
    font-size: 18px;
    font-weight: 400;
}

.panel__main--start__header {
    margin-bottom: 25px;
}

.panel__menu {
    width: 15%;
}

.panel__main {
    width: 80%;
}

.panel__main--start__header .bold {
    color: var(--secondary);
}

.filterSection {
    max-width: 920px;
    margin: auto;
    padding-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transition: .3s all;
}

.filterSection__btn {
    color: var(--secondary);
    font-size: 15px;
    margin-left: 20px;
    text-transform: uppercase;
    background: transparent;
}

.filterBtn,
.filterSection__btn {
    transition: .2s all;
}

.filterBtn:hover,
.filterSection__btn:hover {
    opacity: .8;
}

.product {
    align-items: flex-start;
    margin: 80px auto;
}

.productDetails p,
.product p {
    min-height: 3px;
}

.productDetails *:not(.productDetails__header) {
    line-height: 1.9em;
}

.product__gallery {
    position: sticky;
    top: 30px;
    left: 0;
    width: 45%;
    align-items: flex-start;
}

.product__gallery__miniatures {
    width: 120px;
}

.product__gallery__miniatures__item {
    width: 100px;
    margin-bottom: 10px
}

.product__gallery__main {
    width: calc(100% - 120px);
    position: relative;
}

.product__gallery__main__figure {
    border-radius: 30px;
}

.product__gallery__main__figure--hidden {
    visibility: hidden;
    display: none;
}

.product__gallery__miniatures__item,
.product__gallery__main__figure {
    overflow: hidden;
}

.product__gallery__miniatures__item {
    background: transparent;
}

.product__gallery__miniatures__item .img {
    border-radius: 5px;
}

.product__gallery__btn {
    background: var(--secondary);
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: .2s all;
}

.product__gallery__btn:hover {
    background: var(--hover);
}

.product__gallery__btn .img {
    width: 8px;
}

.product__gallery__btn--prev {
    left: 15px;
}

.product__gallery__btn--next {
    right: 15px;
}

.product--loading {
    padding: 50px 0;
}

.product__gallery__btn--large {
    top: unset;
    transform: none;
    bottom: 30px;
    right: 20px;
}

.product__gallery__btn--large .img {
    width: 18px;
}

.product__content * {
    color: var(--secondary);
}

.product__title {
    font-size: 30px;
}

.product__price {
    font-size: 22px;
    font-weight: 400;
    margin: 20px 0;
}

.product__shortDesc {
    font-size: 16px !important;
    margin-bottom: 40px;
}

.product__shortDesc * {
    font-size: 16px !important;
}

.product__shortDesc > * {
    margin-bottom: 15px;
}

.product__content {
    width: 51%;
}

.addons__header {
    font-size: 24px;
    font-weight: 700;
}

.addon {
    margin-bottom: 40px;
}

.addon__title {
    font-weight: 400;
    font-size: 21px;
    margin: 15px 0 25px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.addon__title__infoBtn {
    background: transparent;
    width: 15px;
    margin-left: 25px;
    display: block;
    position: relative;
}

.addon__title .addon__option__tooltip {
    text-align: center;
}

.addon__title__infoBtn:hover .addon__option__tooltip {
    visibility: visible !important;
    opacity: 1 !important;
    color: #000;
    font-size: 14px;
}

.addon__option__textType {
    display: block;
    border: 1px solid var(--secondary);
    height: 30px;
    border-radius: 30px;
    padding: 5px 20px;
    text-transform: uppercase;
}

.addon__option__imageType {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.addon__option__imageType figure {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
}

.addon__option__imageType figure .img {
    width: 100%;
    min-height: 100%;
}

.addon__option__imageType > span {
    margin-top: 10px;
    text-transform: uppercase;
    font-size: 15px;
}

.addon__options {
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.addon__option {
    background: transparent;
    margin-right: 2%;
    margin-bottom: 15px;
    padding-right: 2vw;
    width: fit-content;
    width: -moz-max-content;
    max-width: 30%;
}

.addon__option--selected .addon__option__imageType > span {
    position: relative;
}

.addon__option--selected .addon__option__imageType > span::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    background: var(--secondary);
}

.addon__option--selected .addon__option__textType {
    border-width: 2px;
    font-weight: 700;
}

.addon__option__textType {
    padding: 5px 40px !important;
    margin-right: -15px;
}

.btn--productContent {
    margin: 30px 0;
    width: 100%;
    max-width: 360px;
    height: 50px;
    border-radius: 40px;
    color: #000;
    font-size: 16px;
    font-weight: 500;
}

.product__productDetailsBtn {
    width: auto;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.product__productDetailsBtn .product__productDetailsBtn__text {
    color: var(--secondary);
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
}

.product__productDetailsBtn__imgWrapper {
    margin-top: 20px;
}

.product__productDetailsBtn__imgWrapper .img {
    width: 25px;
    display: block;
}

.product__productDetailsBtn__imgWrapper .img:last-of-type {
    margin-top: -12px;
}

.productDetails {
    margin: 100px auto;
    padding-top: 30px;
    max-width: 1000px;
}

.productDetails__header {
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    position: relative;
    color: var(--secondary);
    margin-bottom: 30px;
    width: fit-content;
    width: -moz-max-content;
}

.productDetails__header::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    background: var(--secondary);
}

.productDetails > div *,
.productDetails > div {
    color: var(--secondary);
}

.productDetails > div > * {
    margin-bottom: 15px;
}

@media(min-width: 1400px) {
    .page article img {
        max-width: 1000px;
    }
}

.page ul,
.productDetails > div ul {
    list-style-type: disc;
    margin-left: 20px;
}

.productContent__info {
    font-size: 13px;
    max-width: 100%;
    text-align: justify;
}

.payment .info--error,
.contact .info--error,
.product__content .info--error {
    color: #d83b27;
    font-size: 13px;
    width: 100%;
    max-width: 360px;
    text-align: center;
    margin: 20px 0 -10px;
}

.payment .info--error {
    margin: 20px auto;
}

.container--order .order *:not(.form *) {
    color: var(--secondary);
}

.order__header {
    margin: 70px auto 100px;
}

.order__header__h1 {
    font-weight: 700;
    font-size: 35px;
    text-transform: uppercase;
    margin-bottom: 50px;
}

.order__header__steps__item {
    width: 33.333%;
    font-size: 18px;
    font-weight: 500;
}

.order__header__steps__item span {
    padding-right: 8px;
}

.order__header__progress {
    margin-top: 30px;
    background: var(--secondary);
    height: 2px;
    width: 100%;
    position: relative;
}

.order__header__progress__inner {
    position: absolute;
    height: 4px;
    background: var(--secondary);
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    transition: .3s all ease-in-out;
}

.order__main {
    margin: 20px auto 100px;
}

.cart__left {
    width: 65%;
}

.cart__right {
    width: 30%;
}

.cart__table__header {
    padding-bottom: 12px;
    border-bottom: 1px solid var(--secondary);
}

.cart__table__header .cart__table__cell {
    font-size: 14px;
    text-transform: uppercase;
}

.cart__table__cell--0 {
    flex-basis: 40%;
}

.cart__table__cell--1 {
    flex-basis: 14%;
}

.cart__table__cell--2 {
    flex-basis: 24%;
}

.cart__table__cell--3 {
    flex-basis: 18%;
}

.cart__table__row {
    position: relative;
    padding: 30px 0 20px;
}

.cart__table__cell--image {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.cart__table__cell--image figure {
    width: 110px;
    max-width: 110px;
    flex-basis: 100%;
    height: auto;
    overflow: hidden;
    margin-right: 25px;
}

.cart__table__addons {
    padding-bottom: 25px;
    border-bottom: 1px solid var(--secondary);
}

.cart__table__addons__item {
    font-size: 13px;
}

.cart__table__addons__item .bold {
    padding-right: 5px;
}

.cart__table__amountChange {
    width: 100%;
    max-width: 120px;
    height: 50px;
    border: 2px solid var(--secondary);
}

.cart__table__amountChange > * {
    width: 33.333%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}

.cart__table__amountChange__btn {
    background: transparent;
    padding: 0;
    transition: .2s all;
}

.cart__table__amountChange__btn:hover {
    opacity: .8;
}

.shipping__header {
    color: var(--secondary);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
    margin: 40px 0;
}

.shipping__method {
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 45px;
    padding-left: 40px;
}

.shipping__method__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.shipping__method__circle {
    width: 23px;
    height: 23px;
    border-radius: 50%;
    border: 2px solid var(--secondary);
    background: #fff;
    position: relative;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shipping__method--selected .shipping__method__circle::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #000;
}

.shipping__method__img {
    width: 70px;
    height: auto;
    margin: 0 20px;
    display: block;
}

.shipping__method__price,
.shipping__method__name {
    font-weight: 500;
    font-size: 16px;
}

.cart__summary {
    padding: 30px;
    border: 1px solid var(--secondary);
}

.order__main--cart {
    align-items: flex-start;
}

.cart__summary__header {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 25px;
}

.cart__summary__row span {
    font-weight: 500;
    margin: 10px 0;
}

.cart__summary__summaryRow {
    margin-top: 10px;
    border-top: 1px solid var(--secondary);
}

.cart__summary__summaryRow > span {
    font-size: 20px;
    font-weight: 600;
    padding: 20px 0;
}

.btn--cart {
    margin: 20px auto;
    width: 100%;
    max-width: 305px;
    color: #fff !important;
    border-radius: 60px;
    height: 60px;
    font-size: 18px;
    font-weight: 600;
}

.cart__table__remove {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: transparent;
    font-size: 24px;
    font-weight: 400;
    border: none;
    outline: none;
    cursor: pointer;
    transition: .2s all;
}

.cart__table__remove:hover {
    opacity: .8;
}

.emptyCart {
    margin: 200px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.emptyCart__header {
    color: var(--secondary);
    font-size: 32px;
    font-weight: 500;
    text-align: center;
}

.btn--backToShop {
    color: #fff !important;
    width: 350px;
    height: 55px;
    font-weight: 600;
    border-radius: 55px;
    margin: 30px 0;
}

.orderContent {
    opacity: 0;
    transition: .3s opacity;
}

.cart__summary__item {
    margin: 30px 0;
    justify-content: flex-start;
}

.cart__summary__item figure {
    width: 100px;
    margin-right: 25px;
    flex-basis: 100%;
    max-width: 100px;
}

.cart__summary__item__content > * {
    font-weight: 500;
    font-size: 16px;
}

.cart__summary__item__content__header {
    text-transform: uppercase;
}

.cart__summary__item__content__amount {
    margin-top: 35px;
}

.cart__left .btn--submit {
    color: #fff !important;
}

.order__loginForm__header {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin: 0 auto 30px;
    text-transform: uppercase;
}

.order__main--form {
    transition: .3s opacity;
    align-items: flex-start;
}

.order__main--form .cart__left {
    transition: .3s opacity;
}

.order__main__loader {
    width: 100%;
    padding: 30px 0;
}

.order__userData__data {
    align-items: flex-start;
    max-width: 800px;
    margin: 50px auto 0;
}

.container--order .form__addons__label {
    margin: 20px 0;
}

.order__checkboxes {
    border-top: 1px solid var(--secondary);
    margin: 30px 0;
    padding: 20px 0;
}

.order__checkboxes * {
    color: #fff !important;
}

.order__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.btn--order {
    max-width: 305px;
    width: 100%;
    height: 60px;
    border-radius: 60px;
    color: #fff !important;
    font-size: 18px;
    font-weight: 600;
}

.btn--backToCart {
    background: transparent;
    border: none;
    text-decoration: underline;
    font-size: 21px;
    line-height: 21px;
    text-transform: uppercase;
    color: #FAF9F8 !important;
    margin-top: 25px;
    transition: .2s all;
}

.btn--backToCart:hover {
    opacity: .8;
}

.input--order,
.orderForm label {
    width: 100%;
    display: block;
}

.input--order {
    margin-top: 5px;
    height: 55px;
    border-width: 2px;
    color: #fff !important;
}

.orderForm label {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.orderForm label {
    margin-bottom: 20px;
}

.orderForm .flex > label {
    width: 48%;
}

.orderForm .form__addons__label {
    flex-direction: row;
    margin: 25px 0;
}

.container--order .form__addons__label {
    align-items: flex-start;
}

.container--order .form__addons__label span {
    margin-top: -1px;
}

#personalData, #invoice, #deliveryAddress {
    width: 100%;
}

#personalData {
    padding-top: 15px;
}

.info--error--order {
    color: #d83b27 !important;
    margin-bottom: 20px;
}

.orderReceived__header {
    font-size: 18px;
    text-align: center;
}

.orderReceived__header > span {
    display: block;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
}

.orderReceived__info {
    font-size: 16px;
    margin: 30px auto;
    max-width: 500px;
    text-align: center;
    font-weight: 500;
}

.btn--orderReceived {
    width: 100%;
    max-width: 400px;
    height: 60px;
    font-size: 16px;
    border-radius: 60px;
    color: #fff !important;
    margin: 20px auto;
}

.container--loader {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container--loader--blogPost {
    height: 30vh;
}

.ordersTable {
    width: 100%;
    max-width: 1100px;
    border: 1px solid var(--secondary);
}

.ordersTable__header {
    background: var(--secondary);
    color: #222222;
    font-size: 14px;
    padding: 0 20px;
    height: 50px;
}

.ordersTable__header > * {
    color: #222222;
    text-transform: uppercase;
    font-weight: 500;
    text-decoration: none !important;
}

.ordersTable__row {
    padding: 20px;
    min-height: 100px;
    border-bottom: 1px solid var(--secondary);
}

.ordersTable__row > a {
    transition: .2s all;
}

.ordersTable__row > a:hover {
    opacity: .8;
}

.ordersTable > div * {
    display: flex;
    font-weight: 500;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.ordersTable__row > .ordersTable__row__cell {
    color: var(--secondary);
}

.ordersTable > div > *:first-child {
    flex-basis: 10%;
    text-decoration: underline;
}

.ordersTable > div > *:nth-child(2) {
    flex-basis: 12%;
}

.ordersTable > div > *:not(:first-child) {
    flex-basis: 39%;
}

.ordersTable__header {
    height: 40px;
}

.btn--orderList {
    color: #fff !important;
    height: 50px;
    border-radius: 50px;
    width: auto;
    min-width: 200px;
    padding: 0 15px;
    font-size: 14px;
    margin: 10px auto;
    max-width: 45%;
}

.marginTop {
    margin-top: 50px;
}

.ordersTable__row__cell--buttonsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.orderDetails__header {
    font-size: 35px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    color: var(--secondary);
}

.orderDetails__mainInfo {
    border: 1px dashed var(--secondary);
    padding: 20px 80px;
    margin: 50px auto;
    width: 100%;
}

.orderDetails__mainInfo__section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.orderDetails__mainInfo__section > span {
    text-align: center;
    margin: 5px 0;
    color: var(--secondary);
}

.orderDetails__statuses {
    padding-left: 40px;
    margin: 60px auto;
}

.orderDetails__statuses .orderStatus {
    margin-bottom: 50px;
    position: relative;
}

.orderDetails__statuses .orderStatus > span {
    position: relative;
}

.orderDetails__statuses .orderStatus:not(:last-child)::before {
    content: "";
    position: absolute;
    top: 50px;
    height: 65%;
    background: var(--secondary);
    width: 2px;
    left: 20px;
}

.orderDetails__statuses .orderStatus--current::before {
    top: 70px !important;
}

.orderStatus__buttons {
    margin: 15px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.btn--orderDetails {
    height: 50px;
    border-radius: 40px;
    color: #000;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 500;
    margin: 10px 0;
    min-width: 300px;
    max-width: 420px;
    padding: 5px 35px;
}

.orderDetails__statuses .orderStatus {
    align-items: flex-start;
}

.orderDetails__statuses .orderStatus .orderStatus__text {
    margin-top: 15px;
}

.orderDetails__bottom {
    margin: 150px auto 100px;
}

.orderDetails__bottom .orderDetails__header {
    margin-bottom: 70px;
}

.orderDetails__bottom__item {
    margin: 50px 0;
    justify-content: flex-start;
    align-items: flex-start;
}

.orderDetails__bottom__item__figure {
    width: 235px;
    max-width: 235px;
    flex-basis: 100%;
    margin-right: 50px;
    border-radius: 30px;
    overflow: hidden;
}

.orderDetails__bottom * {
    color: var(--secondary)
}

.orderDetails__bottom__item__content__header {
    font-size: 24px;
    font-weight: 600;
}

.orderDetails__bottom__item__content__price {
    font-size: 22px;
    font-weight: 500;
    margin: 15px 0 40px;
}

.orderDetails__bottom__item__addon {
    margin: 15px 0;
    font-size: 14px;
}

.orderDetails__bottom__item__key {
    font-weight: 600;
    padding-right: 5px;
}

.orderDetails__bottom__section {
    margin: 60px 0;
}

.orderDetails__bottom__section__header {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 12px;
}

.orderDetails__bottom__section--last .orderDetails__bottom__section__header {
    margin-bottom: 5px;
}

.panel__main--dataHeader {
    color: var(--secondary);
    margin-bottom: 30px;
}

.orderDetails__bottom__section .marginTop {
    margin-top: 15px;
}

.panel__main--data #personalData > * {
    margin: 20px 0;
}

.panel__main--data #personalData label {
    display: block;
}

.panel__main--data #personalData > div > label {
    width: 48%;
}

.btn--editData {
    height: 60px;
    border-radius: 60px;
    width: 100%;
    display: block;
    font-size: 18px;
    font-weight: 600;
    margin-top: 35px !important;
}

.panel__main--data .info {
    margin-top: 40px !important;
    display: block;
}

.page p {
    text-align: justify;
}

.page img {
    border-radius: 25px;
}

.formPage {
    position: relative;
    padding: 0 0 60px;
    z-index: 2;
    width: 80%;
}

.formPage .pageHeader {
    text-align: center;
    text-transform: uppercase;
}

.formPage * {
    color: var(--secondary);
}

.formEnd {
    margin: 40px auto 15px;
    text-align: center;
}

.formSection {
    border-bottom: 1px solid var(--secondary);
    padding: 40px 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}

.formSection--noBorder {
    border-bottom: none;
}

.formSection__header {
    width: 100%;
    font-size: 21px;
}

.formSection__subheader {
    margin: 15px 30% 30px 0;
    font-size: 15px;
    font-weight: 500;
    width: 40%;
}

.formSection__content {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.formPage__label {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.formPage__label__caption {
    display: block;
    width: 100%;
}

.formPage__label.flex {
    flex-direction: row;
    flex-wrap: wrap;
}

.formPage__label--half {
    width: 45%;
}

.formPage__label .input {
    width: 100%;
    height: 50px;
}

.formPage__label .input::placeholder {
    color: rgba(255, 255, 255, .8);
}

.flex > .formPage__imageWrapper {
    margin-left: 3%;
}

.formSection__img {
    width: 50%;
    position: sticky;
    top: 25px;
    right: 0;
}

.formSection__img figcaption {
    margin-bottom: 10px;
    font-weight: 600;
}

.formPage .btn--submit {
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    max-width: 400px;
    margin: auto;
}

.formPage__imageWrapper {
    position: relative;
    height: 200px;
    width: 100%;
    border: 1px dashed #fff;
    border-radius: 5px;
    margin: 10px 0;
    transition: .2s all;
}

.formPage__label--type1 .formPage__label__caption {
    margin-bottom: 10px;
}

.formPage__imageWrapper .editor__videoWrapper__icon {
    width: 20px;
    margin-left: 10px;
}

.formPage__imageWrapper:hover {
    border-color: var(--secondary);
}

.editor__videoWrapper__placeholderContent--check .editor__videoWrapper__placeholderContent__text {
    color: var(--secondary);
}

.editor__videoWrapper__icon--check {
    filter: none !important;
    max-width: 20px !important;
    margin-left: 10px;
}

.formEnd--type2 {
    margin-bottom: 35px !important;
}

.formPage__imageInput {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 5;
}

.formPage__imageInput {
    position: relative;
}

.formPage__imageWrapper {
    width: 50%;
    height: 50px;
}

.formPage .editor__videoWrapper__placeholderContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%;
}

.editor__videoWrapper__placeholderContent__text {
    font-size: 12px;
    margin-bottom: 0 !important;
}

.formPage__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px auto 20px;
}

.formPage__info__p {
    font-size: 18px;
}

.formPage__info__bottom {
    padding-top: 20px;
}

.formPage__info__bottom > a {
    font-weight: 700;
    text-decoration: underline;
}

.formPage__info__p span:first-of-type {
    padding-right: 8px;
    font-weight: 600;
}

.formPage__imgWrapper {
    width: 100%;
    height: auto;
    min-height: 45px;
    border: 1px dashed #fff;
    border-radius: 5px;
    position: relative;
    margin: 25px 0 20px;
}

.formPage__imgWrapper {
    max-width: 200px;
}

.formPage .input::placeholder {
    opacity: .6;
}

.form__address {
    text-align: center;
    margin-bottom: 30px;
}

.formPage__deleteBtn {
    position: absolute;
    top: -15px;
    right: -15px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 21px;
    background: var(--secondary);
    font-weight: 700;
    transition: .2s all;
}

.formPage__deleteBtn:hover {
    background: var(--secondary);
}

.formPage .input {
    color: #fff;
}

.formPage .info--error {
    color: #d83b27;
    padding-bottom: 20px;
}

.confirmForm__type2,
.confirmForm__type1 {
    margin-bottom: 20px;
}

.confirmForm__type1__key {
    font-weight: 700;
    padding-right: 6px;
}

.confirmForm__type2 figcaption {
    font-weight: 700;
}

.formSection--confirm {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.formSection--confirm:last-of-type {
    border-bottom: none;
}

.confirmForm__type2 .img {
    margin-top: 25px;
}

.confirmForm__type2 {
    max-width: 400px;
}

.formSection--confirm .formSection__header {
    margin-bottom: 30px;
    text-transform: uppercase;
}

.confirmForm__info {
    text-align: center;
    font-size: 15px;
    max-width: 400px;
    margin: 20px auto 50px;
}

.btn--sendForm {
    color: #222222;
    font-size: 18px;
    font-weight: 600;
    width: 400px;
    height: 50px;
    margin: 30px auto;
    border-radius: 50px;
}

.confirmForm .info {
    margin: 20px auto;
    max-width: 400px;
}

.btn--orderList--hidden {
    opacity: .6;
    pointer-events: none;
    cursor: default;
}

.btn--orderList--hidden:hover {
    opacity: .6;
}

.formSection:last-of-type {
    border-bottom: none;
}

.formSection__checkbox > label {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 20px 0;
    width: 100%;
    flex-basis: 100%;
    cursor: pointer;
    position: relative;
}

.formSection__checkbox > label > span {
    padding-top: 5px;
}

.formSection__checkbox > label span {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.formSection__checkbox > label .form__check {
    flex-basis: 100%;
    width: 30px;
    max-width: 30px;
    height: 30px;
}

.newsletterSection .formSection__checkbox > label span,
.contact__bottom .formSection__checkbox > label span {
    display: block;
}

.newsletterSection .formSection__checkbox > label .form__check,
.contact__bottom .formSection__checkbox > label .form__check {
    width: 20px;
    max-width: 20px;
    height: 20px;
}

.newsletterSection .formSection__checkbox > label > span,
.contact__bottom .formSection__checkbox > label > span {
    padding-top: 0;
    font-size: 12px;
}

.newsletterSection .formSection__checkbox > label,
.contact__bottom .formSection__checkbox > label {
    align-items: center;
}

.formSection__header--formType2 {
    margin-bottom: 20px;
}

.formSection__header--formType2 a {
    text-decoration: underline;
}

.formSection--type2 .formSection__content {
    width: 100%;
}

.formSection__imagesMultiple {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.formSection__imagesMultiple .formPage__label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center !important;
}

.input--rendered {
    margin: 0 20px;
}

textarea.input--rendered {
    width: 100%;
    position: absolute;
    bottom: -20px;
    left: 0;
    transform: translateY(100%);
    margin-left: 0;
    padding: 10px;
}

.formSection__checkbox {
    width: 100%;
}

.formSection__checkbox > label input {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.label--withW100Input {
    margin-bottom: 160px !important;
}

textarea {
    resize: none;
}

.formSubmitted__header {
    text-align: center;
    font-size: 24px;
    margin: 60px auto 30px;
}

.formPage--1 .info--success {
    padding-bottom: 25px;
}

.formPage--oldForm .formSection__header {
    margin: 40px 0 20px;
    padding: 15px 0;
}

.formSection--confirm__answer {
    padding-left: 20px;
    position: relative;
    margin: 10px 0;
}

.formSection--confirm__answer::before {
    content: '';
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: var(--secondary);
}

.formSection--confirm__answer--img {
    max-width: 400px;
    margin-bottom: 25px;
}

.formSection--confirm__answer--img figcaption {
    margin-bottom: 10px;
    font-weight: 600;
}

.payment .payment__text,
.payment .pageHeader {
    color: var(--secondary);
    text-align: center;
}

.marginBottom {
    margin-bottom: 25px;
}

.payment .pageHeader {
    position: relative;
    top: unset;
    left: unset;
    text-transform: uppercase;
    margin-top: 50px;
    z-index: 1;
}

.payment .payment__text span {
    color: var(--secondary);
    padding-right: 10px;
    font-weight: 700;
}

.payment .payment__text {
    font-size: 17px;
    margin-top: 10px;
    font-weight: 500;
}

.payment .shipping {
    max-width: 1000px;
    margin: 60px auto;
}

.payment .shipping__method {
    padding-left: 0;
}

.transferData,
.payment .shipping__method * {
    color: var(--secondary);
}

.payment .shipping__method__circle {
    margin-right: 20px;
}

.payment {
    margin-bottom: 100px;
}

.payment .btn {
    margin-top: 60px;
}

.transferData {
    margin-bottom: 20px;
}

.contact {
    margin: 50px auto;
}

.post *,
.blog *,
.contact *:not(input, textarea, button) {
    color: var(--secondary);
}

.post__mainImg {
    max-width: 85%;
    margin-left: auto;
    margin-right: auto;
}

.post * {
    text-align: justify;
}

.post img:not(.post__mainImg img) {
    max-height: 800px;
    width: auto;
}

.contact__top {
    max-width: 1000px;
    margin: 120px auto 100px;
}

.contact__top * {
    text-align: center;
}

.contact__top__section__header {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 20px;
}

.contact__bottom__header {
    text-align: center;
    text-transform: uppercase;
    font-size: 34px;
    margin: 40px auto 30px;
}

.contact__bottom {
    margin: 80px auto 40px;
    max-width: 1000px;
}

.contact__bottom label:not(.form__addons__label) {
    width: 100%;
    margin: 40px auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.contact__bottom .input {
    height: 55px;
    margin-top: 5px;
    width: 100%;
}

.contact__bottom .input--textarea {
    height: 300px !important;
    padding: 15px;
    display: block;
}

.contact__bottom .btn--sendForm {
    max-width: 300px;
}

.contact .info--error {
    text-align: center;
    font-size: 15px;
    max-width: none;
    margin-bottom: 20px;
}

.blog__header {
    margin: 50px auto;
    text-transform: uppercase;
}

.blog__header--post {
    max-width: 1000px;
}

.blog__content {
    margin: 0 auto 100px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 70px;
    grid-column-gap: 50px;
}

.blog__content__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.blog__content__item:hover .blog__content__item__btn::after {
    transform: scaleX(1.15);
}

.blog__content__item__imgWrapper {
    width: 100%;
    border-radius: 30px;
    overflow: hidden;
    height: auto;
}

.blog__content__item__header {
    font-size: 24px;
    font-weight: 600;
    margin-top: 15px;
}

.blog__content__item__excerpt {
    font-size: 15px;
    margin: 15px 0;
}

.blog__content__item__btn {
    display: block;
    font-size: 15px;
    text-transform: uppercase;
    position: relative;
}

.blog__content__item__btn::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 95%;
    height: 1px;
    background: var(--secondary);
    transform-origin: top left;
    transition: .2s all;
}

.blog__pagination {
    margin: 50px auto 100px;
}

.blog__pagination__btn {
    background: transparent;
    border: none;
    text-transform: uppercase;
    font-size: 17px;
    transition: .2s all;
}

.blog__pagination__btn:hover {
    opacity: .8;
}

.blog__pagination__pages {
    display: flex;
    justify-content: center;
    align-items: center;
}

.blog__pagination__pages__btn {
    margin: 0 20px;
    font-size: 17px;
    font-weight: 600;
    position: relative;
    background: transparent;
}

.blog__pagination__pages__btn--selected::after {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 1px;
    background: var(--secondary);
}

.blog__pagination__btn--hidden {
    position: relative;
    z-index: -100;
}

.blog__meta {
    max-width: 1000px;
    justify-content: flex-start;
    margin: -30px auto 25px;
    font-size: 15px;
}

.blog__meta > p:first-of-type {
    margin-right: 25px;
}

.post {
    max-width: 1200px;
}

.post__mainImg {
    width: 100%;
    height: auto;
    margin: 40px auto;
    border-radius: 30px;
    overflow: hidden;
}

.post__article > * {
    margin-bottom: 20px;
}

.post__article > *:not(img, figure) {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.post__article * {
    line-height: 32px;
}

.post__article {
    margin-bottom: 100px;
}

.post__article img {
    max-width: 90vw;
    display: block;
    margin: auto;
    border-radius: 30px;
}

.post__bottom {
    margin: 20px auto 100px;
    padding: 30px 0;
    border-top: 2px solid var(--secondary);
    border-bottom: 2px solid var(--secondary);
}

.post__bottom__btn {
    background: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: .2s all;
}

.post__bottom__btn:hover {
    opacity: .8;
}

.post__bottom__btn > span:first-of-type {
    text-transform: uppercase;
    font-size: 19px;
    font-weight: 600;
}

.post__bottom__btn > span:last-of-type {
    font-size: 15px;
    margin-top: 10px;
}

.post__bottom__btn--hidden {
    position: relative;
    z-index: -100;
}

.post__bottom__btn:last-of-type {
    align-items: flex-end;
}

.post__bottom__btn:last-of-type * {
    text-align: right;
}

.waitlistLabel {
    font-size: 16px;
    font-weight: 500;
    max-width: 400px;
    display: block;
    text-align: center;
}

.waitlistLabel .input {
    margin-top: 30px;
    height: 55px;
    width: 400px;
    color: #fff;
}

.waitlistLabel .input::placeholder {
    color: rgba(255, 255, 255, .8);
}

.btn--waitlist {
    max-width: 400px;
}

.info--waitlist {
    display: block;
    max-width: 400px;
    transition: .2s opacity;
}

.product__content .info--error--waitlist {
    margin: 20px 0;
    width: 100%;
    max-width: 400px;
}

.filterSection__btn--selected {
    font-weight: 700;
}

.remindPassword {
    margin: 50px auto 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: .2s all;
}

.remindPassword *:not(.input, .btn) {
    color: var(--secondary);
}

.remindPassword__text--success {
    margin-top: -10px;
}

.remindPassword label {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 40px 0;
}

.remindPassword .input {
    width: 400px;
    height: 50px;
    margin-top: 5px;
}

.btn--remindPassword {
    width: 400px;
    height: 50px;
    font-size: 17px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #222222;
    font-weight: 600;
}

.remindPassword .info--error {
    color: #d83b27;
    padding-bottom: 20px;
}

.btn--remindPassword--success {
    margin-top: 30px;
}

.verificationPage {
    min-height: 40vh;
}

.btn--verification {
    margin-bottom: 100px;
}

.remindPassword__text,
.verificationPage .shipping__header {
    text-align: center;
}

.newPasswordForm__form {
    margin: 40px auto 80px;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.newPasswordForm__form .input {
    width: 100%;
    height: 50px;
}

.newPasswordForm__form .input::placeholder {
    color: rgba(255, 255, 255, .8);
}

.newPasswordForm__form label {
    margin: 10px 0;
    width: 100%;
}

.newPasswordForm__form .btn--submit {
    margin-top: 20px;
    font-size: 17px;
    font-weight: 600;
    color: #222;
}

.newPasswordForm .shipping__header {
    text-align: center;
    margin-top: 120px;
}

.newPasswordForm .info--error {
    margin-top: 20px;
    font-size: 15px;
}

.btn--remindPassword--passwordForm {
    margin: 20px auto 100px;
}

.remindPassword__text--passwordForm {
    color: var(--secondary);
    margin-bottom: 50px;
}

.fullScreenGallery {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
    transition: .3s opacity ease-in-out;
}

.fullScreenGallery__item {
    display: flex !important;
}

.fullScreenGallery__inner {
    height: 80vh;
    min-width: 300px;
}

.fullScreenGallery__close {
    position: absolute;
    top: 5px;
    right: 25px;
    background: transparent;
    color: #fff;
    font-size: 64px;
    font-weight: 500;
}

.fullScreenGallery__inner * {
    height: 100%;
}

.fullScreenGallery__inner .img {
    width: auto;
    height: 100%;
}

.fullScreenGallery .slick-next,
.fullScreenGallery .slick-prev {
    width: 50px;
    height: 50px;
    background: var(--secondary);
    position: absolute;
    top: 50%;
    left: 50px;
    z-index: 100;
    display: block !important;
    border-radius: 50%;
    transition: .2s all;
}

.fullScreenGallery .slick-next {
    right: 50px;
    left: unset;
}

.fullScreenGallery .slick-active {
    border: none;
}

.newsletterPage .remindPassword__text--success {
    color: var(--secondary);
}

.newsletterPage .btn--remindPassword--success {
    margin: 40px auto 100px;
}

.faq {
    margin-bottom: 80px;
}

.page *,
.faq * {
    color: var(--secondary);
}

.faq .pageHeader {
    text-transform: uppercase;
    margin: 80px 0 50px;
    position: relative;
    z-index: 1;
}

.faq__section {
    margin-bottom: 40px;
}

.faq__section__header {
    font-weight: 500;
    font-size: 26px;
    margin-bottom: 20px;
}

.faq__section__question__question > span:last-of-type {
    padding-left: 15px;
}

.faq__section__question__question {
    background: transparent;
    border: none;
    font-weight: 600;
    font-size: 16px;
    padding: 8px 0;
    border-bottom: 1px solid var(--secondary);
    width: 100%;
    margin-bottom: 25px;
    text-align: left;
}

.faq__section__question__answer {
    line-height: 30px;
    font-size: 14px;
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: .3s all ease-in-out;
}

.faq__section__question__answer a {
    text-decoration: underline;
}

.faq__section__question__answer--visible {
    height: auto;
    opacity: 1;
    margin: 0 0 35px;
}

.page {
    margin: 80px auto;
}

.page img {
    display: block;
    margin: auto;
    max-width: 100%;
}

.page a {
    text-decoration: underline;
    font-weight: 700;
}

.page__content > * {
    margin-bottom: 20px;
}

.page__content * {
    line-height: 32px;
}

.page__content:not(.page100) > *:not(img, figure) {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.page__content figure {
    border-radius: 15px;
    overflow: hidden;
}

.page .pageHeading {
    margin-bottom: 30px;
}

.page .pageHeading:not(.page100) {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

#invoice,
#personalData,
#deliveryAddress {
    position: relative;
}

.anchorSpan {
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    top: -140px;
    left: 0;
}

.addon__option {
    position: relative;
}

.addon__option--textType {
    max-width: 48%;
}

.addon__option__tooltip {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, calc(-100% - 12px));
    padding: 20px;
    background: var(--secondary);
    border-radius: 10px;
    z-index: 1000;
}

.addon__option__tooltip {
    width: 320px;
}

.addon__option__tooltip figure {
    margin-bottom: 5px;
    width: 100%;
}

.addon__option__tooltip span {
    color: #222;
}

.addon__option__tooltip {
    visibility: hidden;
    opacity: 0;
    transition: .3s opacity;
}

@media(min-width: 768px) {
    .addon__option:hover .addon__option__tooltip {
        visibility: visible;
        opacity: 1;
    }
}

@media(max-width: 768px) {
    .addon__option:hover .addon__option__tooltip,
    .addon__option:active .addon__option__tooltip {
        visibility: visible;
        opacity: 1;
    }
}

.contact__top__section__data--phone {
    padding-top: 10px;
    max-width: 320px;
}

.checkboxText a {
    padding: 0 3px;
    opacity: .85;
    transition: .2s all;
}

.checkboxText a:hover {
    opacity: 1;
}

.colorModal label,
.colorModal .input {
    width: 100%;
    height: 45px;
}

.colorModal .form__addons__label {
    margin: 20px 0 0;
}

.colorModal .checkboxText {
    font-size: 12px;
}

.colorModal--redirection .colorModal__inner,
.colorModal--waitlist .colorModal__inner {
    height: 400px;
}

.colorModal--waitlist .btn {
    width: 100%;
}

.colorModal .info {
    font-size: 12px;
    padding-top: 15px;
    margin-bottom: -30px;
}

.colorModal .btn--marginTop {
    margin-top: 40px;
}

.colorModal--waitlist .info--success {
    font-size: 16px;
    margin-top: -15px;
}

.colorModal__header--success {
    margin-top: 10px;
    padding-top: 0;
    text-align: center;
}

.colorModal--waitlist .btn--success {
    width: 300px;
    max-width: 100%;
    margin-top: 60px;
}

.shipping__header--newsletterResignation {
    text-transform: none;
}

.newsletterPage--resignation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.label--newsletterResignation {
    max-width: 90%;
}

.label--newsletterResignation,
.label--newsletterResignation .input {
    width: 400px;
    height: 45px;
}

.label--newsletterResignation .input {
    max-width: 100%;
}

.btn--newsletterResignation {
    width: 400px;
    max-width: 90%;
    margin: 25px auto 100px;
}

.newsletterPage--resignation .info {
    font-size: 13px;
}

.newsletterPage--resignation .center {
    margin: 25px auto;
}

.shipping__header--afterResignationLinkSend {
    margin-bottom: -10px;
}

.cart__table__amountChange__btn:disabled {
    opacity: .5;
    pointer-events: none;
    cursor: default;
}

.example__item {
    width: 32%;
}

.example__img {
    width: 100%;
}

.example__header {
    margin-top: 20px;
    font-size: 21px;
    color: var(--secondary);
    text-align: center;
}

.formSection--type1 {
    justify-content: space-between;
}

.btn--saveForm {
    margin-bottom: 25px !important;
    background: transparent !important;
    text-decoration: underline;
}

.btn--saveForm:hover {
    opacity: .8;
}

.btn--backToEdition {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn--backToEdition .img {
    width: 20px;
    margin-right: 10px;
}

.formSection__checkbox--newsletter {
    max-width: 580px;
    margin: 40px auto 20px;
}

.newsletterSection .formSection__checkbox {
    margin-top: 20px;
}

#CybotCookiebotDialogContentWrapper {
    background: var(--primary);
}

#CybotCookiebotDialogContentWrapper button {
    border-color: #fff !important;
}

#CybotCookiebotDialogContentWrapper * {
    color: var(--secondary) !important;
}

#CybotCookiebotDialogPoweredbyImage {
    filter: brightness(0) invert(1) !important;
}

#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonAccept, #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonAccept, #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    background: var(--secondary) !important;
    color: #fff !important;
    border-color: #fff !important;
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
    border-bottom: 1px solid var(--secondary);
}

.page__content a u {
    font-weight: 400;
}

#termsOfService .indent {
    text-align: left !important;
    display: block !important;
    padding-left: 20px !important;
}

.page__content h4,
#terms_of_service h4 {
    text-align: center;
}

.page__content h5 {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
}

.page table {
    border-top: 2px solid #fff !important;
}

.page table * {
    margin-left: 0 !important;
    text-indent: 0 !important;
    text-align: left !important;
}

.instruction {
    width: 100%;
    max-width: 1000px;
    margin: 30px auto;
    background: #fffefa;
    padding: 40px;
    border-radius: 15px;
}

.instruction__row:not(:last-of-type) {
    margin-bottom: 50px;
}

.instruction .img {
    width: 20%;
    height: auto;
}

.instruction--1 .img {
    width: 10%;
}

.instruction .img--icon {
    width: 25px;
    height: auto;
}

.page__link {
    background: var(--secondary);
    color: #fff;
    text-decoration: none !important;
    padding: 10px 20px;
    min-height: 50px;
    font-size: 15px;
    display: block;
    text-transform: uppercase !important;
    font-weight: 400 !important;
    width: fit-content;
    width: -moz-max-content;
    transition: .2s all;
}

.page__link:hover {
    background: var(--hover);
}

.page__linkWrapper {
    max-width: 1000px;
    width: 100%;
    margin: auto;
}

.page__content--instruction ul {
    padding-left: 30px;
}

.page__images {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 30px auto 40px;
}

.page__images img {
    margin: 0 20px;
    border-radius: 10px;
    max-width: 500px !important;
}

.img--3 {
    width: 30%;
}

.img--2 {
    width: 45%;
}

.img--4 {
    width: 22%;
}

.m1 {
    padding-left: 20px;
}

.text-center {
    text-align: center !important;
}

.page__content--instruction iframe {
    margin: 30px auto;
    display: block;
    max-width: 95% !important;
}

.instruction--example {
    font-style: italic;
}

.instruction--order .img {
    border-radius: 0 !important;
}

.img--bigger {
    transform: scale(1.1);
    transform-origin: center;
}

.img--bigger--2 {
    transform: scale(1.2);
}

.shop__products__item__btn--showModel {
    margin-top: 20px !important;
}

.colorModal__header--waitlist {
    text-align: center;
}

.btn--preview {
    font-size: 18px !important;
    margin-bottom: 20px;
}

.page table *,
.page table {
    border-color: #fff !important;
    color: var(--secondary) !important;
}

.page table {
    width: 100% !important;
}

.notUnderline {
    text-decoration: none !important;
}

.page table:nth-of-type(4) {
    margin-top: 40px;
    border-top: 2px solid #fff;
}
